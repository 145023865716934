import PageTransition from '../components/PageTransition';
import GamingHeading from '../components/GamingHeading';

export default function Cookies() {
  return (
    <PageTransition>
      <div className="min-h-screen bg-gray-900 py-16 px-4">
        <div className="max-w-4xl mx-auto prose prose-invert">
          <GamingHeading level={1} variant="gradient" className="text-4xl mb-8">
            Cookie Policy
          </GamingHeading>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">1. What Are Cookies</h2>
              <p className="text-gray-400">
                Cookies are small text files that are stored on your device when you visit our website. They help us provide you with a better experience by remembering your preferences, analyzing site usage, and assisting with our marketing efforts.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">2. Types of Cookies We Use</h2>
              <div className="text-gray-400 space-y-4">
                <div>
                  <h3 className="text-xl text-white mt-4 mb-2">Essential Cookies</h3>
                  <p>Required for the website to function properly:</p>
                  <ul className="list-disc list-inside space-y-1 ml-4">
                    <li>Authentication status</li>
                    <li>Session management</li>
                    <li>Security features</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl text-white mt-4 mb-2">Preference Cookies</h3>
                  <p>Remember your choices and settings:</p>
                  <ul className="list-disc list-inside space-y-1 ml-4">
                    <li>Language preferences</li>
                    <li>Theme settings</li>
                    <li>User interface customizations</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl text-white mt-4 mb-2">Analytics Cookies</h3>
                  <p>Help us understand how visitors use our site:</p>
                  <ul className="list-disc list-inside space-y-1 ml-4">
                    <li>Pages visited</li>
                    <li>Time spent on site</li>
                    <li>Error encounters</li>
                    <li>Traffic sources</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl text-white mt-4 mb-2">Marketing Cookies</h3>
                  <p>Track advertising and promotional effectiveness:</p>
                  <ul className="list-disc list-inside space-y-1 ml-4">
                    <li>Ad performance tracking</li>
                    <li>Conversion tracking</li>
                    <li>Partner integrations</li>
                  </ul>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">3. Cookie Duration</h2>
              <div className="text-gray-400 space-y-2">
                <p>Cookies we use fall into two categories based on duration:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Session Cookies: Temporary and deleted when you close your browser</li>
                  <li>Persistent Cookies: Remain on your device for a set period or until manually deleted</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">4. Third-Party Cookies</h2>
              <div className="text-gray-400 space-y-2">
                <p>Some cookies are placed by third-party services:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Google Analytics for site analytics</li>
                  <li>Payment processors for transactions</li>
                  <li>Advertising partners for marketing</li>
                  <li>Social media integrations</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">5. Managing Cookies</h2>
              <div className="text-gray-400 space-y-2">
                <p>You can control cookies through your browser settings:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Block all or certain types of cookies</li>
                  <li>Delete existing cookies</li>
                  <li>Receive alerts when cookies are being set</li>
                  <li>Browse in private/incognito mode</li>
                </ul>
                <p className="mt-4">
                  Note: Blocking essential cookies may affect website functionality.
                </p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">6. Updates to Cookie Policy</h2>
              <p className="text-gray-400">
                We may update this Cookie Policy periodically to reflect changes in our practices or for operational, legal, or regulatory reasons. We encourage you to review this policy regularly.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">7. Contact Information</h2>
              <p className="text-gray-400">
                If you have questions about our use of cookies, please contact us at privacy@gainjourney.com
              </p>
            </section>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}