import { useState } from 'react';
import { motion } from 'framer-motion';
import { Mail, MessageSquare, Send } from 'lucide-react';
import PageTransition from '../components/PageTransition';
import GamingHeading from '../components/GamingHeading';
import GamingButton from '../components/GamingButton';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    window.location.href = `mailto:support@gainjourney.com?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(formData.message)}`;
  };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gray-900 py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <GamingHeading level={1} variant="gradient" className="text-4xl mb-4">
              Contact Us
            </GamingHeading>
            <p className="text-gray-400">
              Have questions? We're here to help!
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Contact Info */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="space-y-8"
            >
              <div>
                <h2 className="text-xl font-cyber text-white mb-4">Get in Touch</h2>
                <p className="text-gray-400">
                  Our support team is available 24/7 to assist you with any questions or concerns.
                </p>
              </div>

              <div className="space-y-4">
                <div className="flex items-center space-x-4">
                  <div className="w-12 h-12 rounded-full bg-cyan-500/20 flex items-center justify-center">
                    <Mail className="h-6 w-6 text-cyan-400" />
                  </div>
                  <div>
                    <h3 className="text-white font-medium">Email</h3>
                    <a 
                      href="mailto:support@gainjourney.com" 
                      className="text-gray-400 hover:text-cyan-400 transition-colors"
                    >
                      support@gainjourney.com
                    </a>
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="w-12 h-12 rounded-full bg-cyan-500/20 flex items-center justify-center">
                    <MessageSquare className="h-6 w-6 text-cyan-400" />
                  </div>
                  <div>
                    <h3 className="text-white font-medium">Business Inquiries</h3>
                    <a 
                      href="mailto:business@gainjourney.com"
                      className="text-gray-400 hover:text-cyan-400 transition-colors"
                    >
                      business@gainjourney.com
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-cyan-500/10 rounded-lg p-6">
                <h3 className="text-lg font-medium text-white mb-2">Office Hours</h3>
                <p className="text-gray-400">
                  Monday - Friday: 9:00 AM - 6:00 PM EST<br />
                  Saturday - Sunday: 10:00 AM - 4:00 PM EST
                </p>
              </div>
            </motion.div>

            {/* Contact Form */}
            <motion.form
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              onSubmit={handleSubmit}
              className="space-y-6"
            >
              <div>
                <label className="block text-white mb-2">Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-white mb-2">Email</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-white mb-2">Subject</label>
                <input
                  type="text"
                  value={formData.subject}
                  onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  required
                />
              </div>

              <div>
                <label className="block text-white mb-2">Message</label>
                <textarea
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white h-32"
                  required
                />
              </div>

              <GamingButton
                type="submit"
                variant="primary"
                className="w-full"
                glowIntensity="high"
              >
                <Send className="h-5 w-5 mr-2" />
                Send Message
              </GamingButton>
            </motion.form>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}