import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Trophy, Star, Zap, Sparkles, Crown, Coins, ChevronRight, Gift, Users, DollarSign, Quote } from 'lucide-react';
import GamingCard from '../components/GamingCard';
import GamingButton from '../components/GamingButton';
import GamingHeading from '../components/GamingHeading';
import AnimatedBackground from '../components/AnimatedBackground';
import PageTransition from '../components/PageTransition';
import EarningsCalculator from '../components/EarningsCalculator';
import PayoutProofs from '../components/PayoutProofs';
import Testimonials from '../components/Testimonials';

const FEATURES = [
  {
    icon: Trophy,
    title: 'Complete Offers',
    description: 'Earn points by completing surveys and offers',
    gradient: 'from-yellow-500 to-amber-500'
  },
  {
    icon: Star,
    title: 'Daily Rewards',
    description: 'Login daily to claim bonus points and rewards',
    gradient: 'from-purple-500 to-pink-500'
  },
  {
    icon: Zap,
    title: 'Instant Payouts',
    description: 'Withdraw your earnings instantly via PayPal or Crypto',
    gradient: 'from-cyan-500 to-blue-500'
  }
];

const STATS = [
  { label: 'Active Users', value: '25,000+', icon: Users },
  { label: 'Total Paid', value: '$150,000+', icon: DollarSign },
  { label: 'Daily Rewards', value: '$500+', icon: Gift },
  { label: 'Avg. Earnings', value: '$40/day', icon: Trophy }
];

export default function Home() {
  return (
    <PageTransition>
      <div className="min-h-screen relative overflow-hidden bg-gray-900">
        <AnimatedBackground />
        
        <div className="relative z-10">
          {/* Hero Section */}
          <div className="pt-20 pb-16 text-center px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="max-w-7xl mx-auto"
            >
              {/* Animated Logo */}
              <div className="flex justify-center mb-8">
                <motion.div
                  animate={{ 
                    rotate: 360,
                    scale: [1, 1.2, 1]
                  }}
                  transition={{ 
                    rotate: { duration: 20, repeat: Infinity, ease: "linear" },
                    scale: { duration: 2, repeat: Infinity, ease: "easeInOut" }
                  }}
                  className="relative"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-cyan-500 to-purple-500 rounded-full blur-xl opacity-50 animate-pulse" />
                  <div className="relative bg-gradient-to-r from-cyan-500/20 to-purple-500/20 p-8 rounded-full">
                    <Coins className="h-20 w-20 text-yellow-400" />
                  </div>
                </motion.div>
              </div>

              {/* Animated Badge */}
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.3, type: "spring" }}
                className="flex justify-center mb-6"
              >
                <div className="bg-gradient-to-r from-cyan-500/10 to-purple-500/10 border border-cyan-500/20 rounded-full px-4 py-2 flex items-center space-x-2">
                  <Sparkles className="h-4 w-4 text-cyan-400" />
                  <span className="text-cyan-400 font-cyber text-sm">Rewards Platform</span>
                  <Star className="h-4 w-4 text-yellow-400" />
                </div>
              </motion.div>

              {/* Main Heading */}
              <div className="relative mb-6">
                <GamingHeading level={1} variant="gradient" className="text-5xl sm:text-6xl md:text-7xl font-bold">
                  Level Up Your Earnings
                </GamingHeading>
                <motion.div
                  animate={{
                    opacity: [0.5, 1, 0.5],
                    y: [-2, 2, -2]
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                  className="absolute -top-8 right-1/4 transform rotate-12"
                >
                  <Crown className="h-8 w-8 text-yellow-400" />
                </motion.div>
              </div>

              {/* Subheading */}
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="text-xl sm:text-2xl mb-8 max-w-3xl mx-auto text-gray-300"
              >
                Complete offers, earn points, get{' '}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-400">
                  instant rewards
                </span>
              </motion.p>

              {/* Quick Stats */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                className="flex flex-wrap justify-center gap-4 mb-8"
              >
                {STATS.map((stat, index) => (
                  <div
                    key={index}
                    className="flex items-center space-x-2 bg-gradient-to-r from-gray-800 to-gray-900 px-4 py-2 rounded-full border border-cyan-500/20"
                  >
                    <stat.icon className="h-4 w-4 text-cyan-400" />
                    <span className="text-gray-300 text-sm">{stat.value}</span>
                  </div>
                ))}
              </motion.div>

              {/* CTA Buttons */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="flex flex-col sm:flex-row gap-4 justify-center"
              >
                <GamingButton
                  variant="primary"
                  size="lg"
                  glowIntensity="high"
                  className="group"
                >
                  <Link to="/offers" className="flex items-center">
                    Start Earning
                    <ChevronRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                  </Link>
                </GamingButton>
                
                <GamingButton
                  variant="secondary"
                  size="lg"
                >
                  <Link to="/leaderboard" className="flex items-center">
                    View Leaderboard
                    <Trophy className="ml-2 h-5 w-5" />
                  </Link>
                </GamingButton>
              </motion.div>
            </motion.div>
          </div>

          {/* Features Grid */}
          <div className="max-w-7xl mx-auto px-4 py-16">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {FEATURES.map((feature, index) => (
                <GamingCard
                  key={index}
                  className="p-6"
                >
                  <div className="relative z-10">
                    <div className={`inline-block p-3 rounded-xl bg-gradient-to-br ${feature.gradient} mb-4`}>
                      <feature.icon className="h-6 w-6 text-white" />
                    </div>
                    <h3 className="text-xl font-bold text-white mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </GamingCard>
              ))}
            </div>
          </div>

          {/* Earnings Calculator */}
          <div className="max-w-7xl mx-auto px-4 py-16">
            <div className="text-center mb-12">
              <GamingHeading level={2} variant="gradient" className="text-4xl mb-4">
                Calculate Your Earnings
              </GamingHeading>
              <p className="text-gray-400">
                See how much you can earn based on your activity level
              </p>
            </div>
            <EarningsCalculator />
          </div>

          {/* Payment Proofs */}
          <div className="max-w-7xl mx-auto px-4 py-16">
            <div className="text-center mb-12">
              <GamingHeading level={2} variant="gradient" className="text-4xl mb-4">
                Recent Payouts
              </GamingHeading>
              <p className="text-gray-400">
                Join thousands of users who get paid daily
              </p>
            </div>
            <PayoutProofs />
          </div>

          {/* Testimonials */}
          <div className="max-w-7xl mx-auto px-4 py-16 bg-gradient-to-b from-transparent to-gray-900/50">
            <div className="text-center mb-12">
              <GamingHeading level={2} variant="gradient" className="text-4xl mb-4">
                User Testimonials
              </GamingHeading>
              <p className="text-gray-400">
                Hear what our community has to say
              </p>
            </div>
            <Testimonials />
          </div>

          {/* Final CTA */}
          <div className="text-center py-16">
            <GamingButton
              variant="primary"
              size="lg"
              glowIntensity="high"
              className="animate-pulse"
            >
              <Link to="/offers" className="flex items-center">
                Start Earning Now <Sparkles className="ml-2 h-5 w-5" />
              </Link>
            </GamingButton>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}