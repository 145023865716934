import { useState } from 'react';
import { motion } from 'framer-motion';
import { Mail, Eye, EyeOff, User, Key, Globe, Gift } from 'lucide-react';
import GamingButton from '../GamingButton';
import { COUNTRIES } from '../../constants/countries';
import { validateSignupData } from '../../lib/validation';

interface SignupFormProps {
  onSubmit: (data: SignupFormData) => void;
  loading: boolean;
}

export interface SignupFormData {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  country: string;
  referralCode: string;
}

export default function SignupForm({ onSubmit, loading }: SignupFormProps) {
  const [formData, setFormData] = useState<SignupFormData>({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    country: '',
    referralCode: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<Partial<Record<keyof SignupFormData, boolean>>>({});

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Reset errors
    setErrors({});

    // Validate form data
    if (validateSignupData(formData)) {
      onSubmit(formData);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'referralCode' ? value.toUpperCase() : value
    }));
    // Clear error when field is modified
    setErrors(prev => ({ ...prev, [name]: false }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Email */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Email Address
        </label>
        <div className="relative">
          <Mail className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`pl-10 w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 ${
              errors.email ? 'border-red-500' : 'border-gray-700'
            }`}
            placeholder="Enter your email"
            required
          />
        </div>
      </div>

      {/* Password */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Password
        </label>
        <div className="relative">
          <Key className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            className={`pl-10 w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 ${
              errors.password ? 'border-red-500' : 'border-gray-700'
            }`}
            placeholder="Enter your password"
            required
            minLength={6}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-2.5 text-gray-400 hover:text-white"
          >
            {showPassword ? (
              <EyeOff className="h-5 w-5" />
            ) : (
              <Eye className="h-5 w-5" />
            )}
          </button>
        </div>
      </div>

      {/* Confirm Password */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Confirm Password
        </label>
        <div className="relative">
          <Key className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type={showPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className={`pl-10 w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 ${
              errors.confirmPassword ? 'border-red-500' : 'border-gray-700'
            }`}
            placeholder="Confirm your password"
            required
          />
        </div>
      </div>

      {/* Name */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Full Name
        </label>
        <div className="relative">
          <User className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`pl-10 w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 ${
              errors.name ? 'border-red-500' : 'border-gray-700'
            }`}
            placeholder="Enter your name"
            required
          />
        </div>
      </div>

      {/* Country */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Country
        </label>
        <div className="relative">
          <Globe className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <select
            name="country"
            value={formData.country}
            onChange={handleChange}
            className={`pl-10 w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 ${
              errors.country ? 'border-red-500' : 'border-gray-700'
            }`}
            required
          >
            <option value="">Select your country</option>
            {COUNTRIES.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Referral Code */}
      <div>
        <label className="block text-sm font-medium text-gray-400 mb-2">
          Referral Code (Optional)
        </label>
        <div className="relative">
          <Gift className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="text"
            name="referralCode"
            value={formData.referralCode}
            onChange={handleChange}
            className={`pl-10 w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500 ${
              errors.referralCode ? 'border-red-500' : 'border-gray-700'
            }`}
            placeholder="Enter referral code"
            maxLength={6}
          />
        </div>
      </div>

      {/* Submit Button */}
      <GamingButton
        type="submit"
        variant="primary"
        className="w-full"
        disabled={loading}
        glowIntensity="high"
      >
        {loading ? 'Creating Account...' : 'Create Account'}
      </GamingButton>
    </form>
  );
}