import { forwardRef, ReactNode } from 'react';
import { motion } from 'framer-motion';

interface GamingButtonProps {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  variant?: 'primary' | 'secondary' | 'accent';
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit' | 'reset';
  glowIntensity?: 'low' | 'medium' | 'high';
}

const GamingButton = forwardRef<HTMLButtonElement, GamingButtonProps>(({
  children,
  onClick,
  disabled = false,
  className = '',
  variant = 'primary',
  size = 'md',
  type = 'button',
  glowIntensity = 'medium'
}, ref) => {
  const baseStyles = 'relative overflow-hidden font-cyber tracking-wider uppercase transition-all duration-300';
  
  const variantStyles = {
    primary: 'bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:from-cyan-600 hover:to-blue-600',
    secondary: 'bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:from-purple-600 hover:to-pink-600',
    accent: 'bg-gradient-to-r from-amber-500 to-orange-500 text-white hover:from-amber-600 hover:to-orange-600'
  };

  const sizeStyles = {
    sm: 'px-4 py-2 text-sm',
    md: 'px-6 py-3 text-base',
    lg: 'px-8 py-4 text-lg'
  };

  const glowStyles = {
    low: 'shadow-[0_0_10px_rgba(0,242,254,0.3)]',
    medium: 'shadow-[0_0_20px_rgba(0,242,254,0.5)]',
    high: 'shadow-[0_0_30px_rgba(0,242,254,0.7)]'
  };

  return (
    <motion.button
      ref={ref}
      type={type}
      whileHover={disabled ? undefined : { scale: 1.05 }}
      whileTap={disabled ? undefined : { scale: 0.95 }}
      onClick={onClick}
      disabled={disabled}
      className={`
        ${baseStyles}
        ${variantStyles[variant]}
        ${sizeStyles[size]}
        ${glowStyles[glowIntensity]}
        ${className}
        rounded-lg
        disabled:opacity-50
        disabled:cursor-not-allowed
        group
      `}
    >
      {/* Shine effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />

      {/* Button content */}
      <div className="relative z-10 flex items-center justify-center gap-2">
        {children}
      </div>
    </motion.button>
  );
});

GamingButton.displayName = 'GamingButton';

export default GamingButton;