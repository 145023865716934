import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, HelpCircle } from 'lucide-react';
import PageTransition from '../components/PageTransition';
import GamingHeading from '../components/GamingHeading';

const FAQS = [
  {
    question: 'How do I earn points?',
    answer: 'You can earn points by completing offers, surveys, watching videos, and referring friends. Each activity has different point values, and you can see these before starting.'
  },
  {
    question: 'How long does it take to receive rewards?',
    answer: 'Most rewards are processed within 24-48 hours. Some payment methods like PayPal and cryptocurrency can be instant once approved.'
  },
  {
    question: 'What rewards can I redeem?',
    answer: 'We offer various rewards including PayPal cash, gift cards (Amazon, Steam, Google Play), and cryptocurrency. The minimum withdrawal amount varies by reward type.'
  },
  {
    question: 'Are there any fees?',
    answer: 'No, there are no fees to use our platform or redeem rewards. What you earn is what you get.'
  },
  {
    question: 'How does the referral system work?',
    answer: 'You get a unique referral code to share with friends. When they sign up and complete offers, you earn a percentage of their earnings as bonus points.'
  }
];

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <PageTransition>
      <div className="min-h-screen bg-gray-900 py-16 px-4">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <GamingHeading level={1} variant="gradient" className="text-4xl mb-4">
              Frequently Asked Questions
            </GamingHeading>
            <p className="text-gray-400">
              Find answers to common questions about our platform
            </p>
          </div>

          <div className="space-y-4">
            {FAQS.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="border border-cyan-500/20 rounded-lg overflow-hidden bg-gray-800/50 backdrop-blur-sm"
              >
                <button
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                  className="w-full px-6 py-4 flex items-center justify-between text-left"
                >
                  <span className="font-medium text-white">{faq.question}</span>
                  <ChevronDown
                    className={`h-5 w-5 text-cyan-400 transform transition-transform duration-200 ${
                      openIndex === index ? 'rotate-180' : ''
                    }`}
                  />
                </button>

                <AnimatePresence>
                  {openIndex === index && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="border-t border-cyan-500/20"
                    >
                      <div className="px-6 py-4 text-gray-400">
                        {faq.answer}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>

          <div className="mt-12 text-center">
            <p className="text-gray-400">
              Still have questions?{' '}
              <Link
                to="/contact"
                className="text-cyan-400 hover:text-cyan-300 transition-colors"
              >
                Contact our support team
              </Link>
            </p>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}