import { motion } from 'framer-motion';
import { Users, Gift, Copy, Share2 } from 'lucide-react';
import { useUser } from '../hooks/useUser';
import { toast } from 'react-hot-toast';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';

export default function ReferralStats() {
  const { user } = useUser();

  const copyReferralCode = async () => {
    if (!user?.referralCode) return;
    
    try {
      await navigator.clipboard.writeText(user.referralCode);
      toast.success('Referral code copied!');
    } catch (error) {
      toast.error('Failed to copy code');
    }
  };

  const shareReferralCode = async () => {
    if (!user?.referralCode) return;

    const shareData = {
      title: 'Join GainJourney',
      text: `Use my referral code ${user.referralCode} to get 1000 bonus points!`,
      url: window.location.origin
    };

    try {
      if (navigator.share && navigator.canShare(shareData)) {
        await navigator.share(shareData);
        toast.success('Thanks for sharing!');
      } else {
        await copyReferralCode();
      }
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error('Error sharing:', error);
      }
    }
  };

  return (
    <GamingCard className="p-6">
      <div className="space-y-6">
        {/* Referral Code */}
        <div className="bg-gray-800/50 rounded-lg p-4">
          <p className="text-sm text-gray-400 mb-2">Your Referral Code</p>
          <div className="flex items-center space-x-2">
            <code className="flex-grow px-4 py-2 bg-gray-900 rounded-lg font-mono text-lg text-cyan-400 tracking-wider">
              {user?.referralCode || 'Loading...'}
            </code>
            <GamingButton
              variant="secondary"
              size="sm"
              onClick={copyReferralCode}
              className="hover:scale-105 transition-transform"
            >
              <Copy className="h-4 w-4" />
            </GamingButton>
            <GamingButton
              variant="secondary"
              size="sm"
              onClick={shareReferralCode}
              className="hover:scale-105 transition-transform"
            >
              <Share2 className="h-4 w-4" />
            </GamingButton>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-gray-800/50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm text-gray-400">Total Referrals</p>
              <Users className="h-4 w-4 text-cyan-400" />
            </div>
            <p className="text-2xl font-bold text-white">
              {user?.referrals?.length || 0}
            </p>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm text-gray-400">Referral Earnings</p>
              <Gift className="h-4 w-4 text-cyan-400" />
            </div>
            <p className="text-2xl font-bold text-white">
              {user?.bonusPoints?.referral?.toLocaleString() || 0}
            </p>
          </div>
        </div>

        {/* Info Box */}
        <div className="bg-cyan-500/10 rounded-lg p-4">
          <div className="flex items-center space-x-2 mb-2">
            <Gift className="h-5 w-5 text-cyan-400" />
            <p className="text-cyan-400 font-medium">Referral Rewards</p>
          </div>
          <p className="text-sm text-gray-300">
            Earn 1000 points for each friend who signs up using your code, plus 30% of their earnings forever!
          </p>
        </div>
      </div>
    </GamingCard>
  );
}