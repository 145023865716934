import PageTransition from '../components/PageTransition';
import GamingHeading from '../components/GamingHeading';

export default function Terms() {
  return (
    <PageTransition>
      <div className="min-h-screen bg-gray-900 py-16 px-4">
        <div className="max-w-4xl mx-auto prose prose-invert">
          <GamingHeading level={1} variant="gradient" className="text-4xl mb-8">
            Terms of Service
          </GamingHeading>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">1. Acceptance of Terms</h2>
              <p className="text-gray-400">
                By accessing and using GainJourney, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing this site.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">2. Eligibility</h2>
              <div className="text-gray-400 space-y-2">
                <p>To use GainJourney, you must:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Be at least 18 years old</li>
                  <li>Have a valid email address</li>
                  <li>Provide accurate and truthful information</li>
                  <li>Not be located in a restricted jurisdiction</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">3. Account Rules</h2>
              <div className="text-gray-400 space-y-2">
                <p>Users are prohibited from:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Creating multiple accounts</li>
                  <li>Using VPNs or proxies</li>
                  <li>Automating any platform activities</li>
                  <li>Sharing accounts with others</li>
                  <li>Selling or transferring accounts</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">4. Points and Rewards</h2>
              <div className="text-gray-400 space-y-2">
                <p>Understanding our points system:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Points have no monetary value</li>
                  <li>Points cannot be transferred between accounts</li>
                  <li>Points expire after 12 months of account inactivity</li>
                  <li>We reserve the right to adjust point values and rewards</li>
                  <li>Minimum withdrawal amounts apply</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">5. Referral Program</h2>
              <div className="text-gray-400 space-y-2">
                <p>Rules for our referral system:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Only one referral code can be used per account</li>
                  <li>Self-referrals are prohibited</li>
                  <li>Referral rewards are subject to verification</li>
                  <li>Abuse of the referral system may result in account termination</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">6. Content Guidelines</h2>
              <p className="text-gray-400">
                Users must not post, upload, or share any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">7. Termination</h2>
              <div className="text-gray-400 space-y-2">
                <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason including:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Violation of these Terms</li>
                  <li>Suspicious activity</li>
                  <li>Fraudulent behavior</li>
                  <li>Extended periods of inactivity</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">8. Changes to Terms</h2>
              <p className="text-gray-400">
                We reserve the right to modify or replace these Terms at any time. Changes will be effective immediately upon posting. Continued use of the platform after any changes constitutes acceptance of the new Terms.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">9. Contact Information</h2>
              <p className="text-gray-400">
                If you have any questions about these Terms, please contact us at support@gainjourney.com
              </p>
            </section>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}