import { useState, useEffect } from 'react';
import { ExternalLink, Search, Star, Clock, TrendingUp, Loader2, AlertCircle } from 'lucide-react';
import { useOffers } from '../hooks/useOffers';
import { useLeadTracking } from '../hooks/useLeadTracking';
import { motion, AnimatePresence } from 'framer-motion';
import PageTransition from '../components/PageTransition';
import { usePoints } from '../hooks/usePoints';
import GamingCard from '../components/GamingCard';
import GamingButton from '../components/GamingButton';
import GamingHeading from '../components/GamingHeading';
import { toast } from 'react-hot-toast';
import type { Offer } from '../types';

export default function Offers() {
  const { offers, loading, error, retryLoading } = useOffers();
  const { updateUserPoints } = useLeadTracking();
  const { calculateMultiplier } = usePoints();
  const [openOffers, setOpenOffers] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState<'points' | 'time'>('points');

  const multiplier = calculateMultiplier();

  const handleOfferClick = async (offer: Offer) => {
    if (openOffers.has(offer.id)) {
      toast.info('Offer is already in progress');
      return;
    }

    // Add offer to tracking set
    setOpenOffers(prev => new Set([...prev, offer.id]));

    // Open offer in new tab
    window.open(offer.url, '_blank');

    toast.loading('Offer started! Complete it to earn points.', {
      id: `offer-${offer.id}`,
      duration: Infinity
    });

    // Start checking for completion
    const checkInterval = setInterval(async () => {
      try {
        const points = await updateUserPoints(offer.payout, offer.id);
        if (points > 0) {
          clearInterval(checkInterval);
          setOpenOffers(prev => {
            const next = new Set(prev);
            next.delete(offer.id);
            return next;
          });
          toast.success(`Offer completed! +${points} points`, {
            id: `offer-${offer.id}`
          });
        }
      } catch (error) {
        console.error('Error checking offer completion:', error);
      }
    }, 30000); // Check every 30 seconds

    // Cleanup after 1 hour
    setTimeout(() => {
      clearInterval(checkInterval);
      setOpenOffers(prev => {
        const next = new Set(prev);
        next.delete(offer.id);
        return next;
      });
      toast.error('Offer expired. Try again.', {
        id: `offer-${offer.id}`
      });
    }, 3600000);
  };

  // Filter and sort offers
  const filteredOffers = offers.filter(offer => 
    offer.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    offer.description.toLowerCase().includes(searchTerm.toLowerCase())
  ).sort((a, b) => {
    if (sortBy === 'points') {
      return b.points - a.points;
    }
    return a.estimatedTime - b.estimatedTime;
  });

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-cyan-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <GamingCard className="p-6">
          <div className="flex flex-col items-center space-y-4">
            <AlertCircle className="h-12 w-12 text-red-400" />
            <p className="text-red-400">{error}</p>
            <GamingButton variant="primary" onClick={retryLoading}>
              Try Again
            </GamingButton>
          </div>
        </GamingCard>
      </div>
    );
  }

  return (
    <PageTransition>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
          <div>
            <GamingHeading level={1} variant="gradient" className="text-3xl mb-2">
              Available Offers
            </GamingHeading>
            <div className="flex items-center space-x-2 text-gray-400">
              <Star className="h-5 w-5 text-yellow-400" />
              <span>Current Multiplier: {multiplier.toFixed(1)}x</span>
            </div>
          </div>

          {/* Search and Sort */}
          <div className="flex flex-col md:flex-row gap-4 mt-4 md:mt-0">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search offers..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 w-full md:w-64 px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
              />
            </div>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as 'points' | 'time')}
              className="px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-cyan-500 focus:border-cyan-500"
            >
              <option value="points">Sort by Points</option>
              <option value="time">Sort by Time</option>
            </select>
          </div>
        </div>

        {/* Offers Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <AnimatePresence mode="popLayout">
            {filteredOffers.map((offer: Offer) => {
              const basePoints = offer.points;
              const finalPoints = Math.floor(basePoints * multiplier);
              const isInProgress = openOffers.has(offer.id);
              
              return (
                <motion.div
                  key={offer.id}
                  layout
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.2 }}
                >
                  <GamingCard className="h-full">
                    <div className="p-6">
                      <div className="flex justify-between items-start mb-4">
                        <h3 className="text-lg font-semibold text-white line-clamp-2">
                          {offer.title}
                        </h3>
                        <div className="flex flex-col items-end">
                          <div className="flex items-center space-x-1 bg-cyan-500/20 px-3 py-1.5 rounded-full">
                            <Star className="h-4 w-4 text-cyan-400" />
                            <span className="text-sm font-medium text-cyan-400">
                              {finalPoints.toLocaleString()} pts
                            </span>
                          </div>
                          {multiplier > 1 && (
                            <div className="text-xs text-green-400 mt-1">
                              Base: {basePoints.toLocaleString()} pts
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="text-gray-400 mb-4 line-clamp-3">
                        {offer.description}
                      </p>
                      <div className="flex items-center justify-between mt-4">
                        <div className="flex items-center space-x-3">
                          <div className="flex items-center text-sm text-gray-400">
                            <Clock className="h-4 w-4 mr-1" />
                            <span>~{offer.estimatedTime} min</span>
                          </div>
                          {multiplier > 1 && (
                            <div className="flex items-center text-sm text-green-400">
                              <TrendingUp className="h-4 w-4 mr-1" />
                              <span>{multiplier.toFixed(1)}x</span>
                            </div>
                          )}
                        </div>
                        <GamingButton
                          variant="primary"
                          size="sm"
                          onClick={() => handleOfferClick(offer)}
                          disabled={isInProgress}
                        >
                          {isInProgress ? (
                            <Loader2 className="h-4 w-4 animate-spin" />
                          ) : (
                            <>
                              Start
                              <ExternalLink className="ml-2 h-4 w-4" />
                            </>
                          )}
                        </GamingButton>
                      </div>
                    </div>
                  </GamingCard>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </div>

        {/* Empty State */}
        {filteredOffers.length === 0 && (
          <div className="text-center py-12">
            <AlertCircle className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-white mb-2">No offers found</h3>
            <p className="text-gray-400">
              Try adjusting your search criteria
            </p>
          </div>
        )}
      </div>
    </PageTransition>
  );
}