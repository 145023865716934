import { doc, updateDoc, increment, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { toast } from 'react-hot-toast';

// Points calculation constants
export const POINTS_MULTIPLIERS = {
  STREAK_DAILY: 0.1, // 10% per day up to 100%
  ACHIEVEMENT: 0.1, // 10% per achievement up to 50%
  LEVEL: 0.02, // 2% per level up to 50%
  REFERRAL: 0.1, // 10% of referred user's earnings
};

export const POINTS_CAPS = {
  STREAK: 1.0, // 100% max bonus
  ACHIEVEMENT: 0.5, // 50% max bonus
  LEVEL: 0.5, // 50% max bonus
};

// Points conversion rates
export const POINTS_CONVERSION = {
  DOLLAR_TO_POINTS: 1000, // $1 = 1000 points
  POINTS_TO_DOLLAR: 0.001, // 1000 points = $1
};

// Calculate points for an offer based on dollar value
export function calculateOfferPoints(dollarValue: number, multiplier: number = 1): number {
  const basePoints = Math.floor(dollarValue * POINTS_CONVERSION.DOLLAR_TO_POINTS);
  return Math.floor(basePoints * multiplier);
}

// Calculate points needed for next level
export function calculatePointsForLevel(level: number): number {
  return Math.floor(level * level * 1000); // Increased base points per level
}

// Calculate current level based on points
export function calculateLevel(points: number): number {
  return Math.floor(Math.sqrt(points / 1000)) + 1;
}

// Calculate progress to next level (0-100)
export function calculateLevelProgress(points: number): number {
  const currentLevel = calculateLevel(points);
  const currentLevelPoints = calculatePointsForLevel(currentLevel - 1);
  const nextLevelPoints = calculatePointsForLevel(currentLevel);
  const progress = ((points - currentLevelPoints) / (nextLevelPoints - currentLevelPoints)) * 100;
  return Math.min(Math.max(progress, 0), 100);
}

// Calculate points multiplier based on user stats
export function calculateMultiplier(streakDays: number = 0, achievements: number = 0, level: number = 1): number {
  const streakBonus = Math.min(streakDays * POINTS_MULTIPLIERS.STREAK_DAILY, POINTS_CAPS.STREAK);
  const achievementBonus = Math.min(achievements * POINTS_MULTIPLIERS.ACHIEVEMENT, POINTS_CAPS.ACHIEVEMENT);
  const levelBonus = Math.min(level * POINTS_MULTIPLIERS.LEVEL, POINTS_CAPS.LEVEL);
  
  const totalMultiplier = 1 + streakBonus + achievementBonus + levelBonus;
  return Number(totalMultiplier.toFixed(2));
}

// Add points to user account
export async function addPoints(
  userId: string,
  amount: number,
  type: 'offer' | 'daily' | 'achievement' | 'referral' | 'game' | 'bonus',
  description: string,
  multiplier: number = 1
): Promise<boolean> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    if (userData.isBanned) {
      throw new Error('Account is banned');
    }

    const finalAmount = Math.floor(amount * multiplier);
    const transaction = {
      id: `${type}-${Date.now()}`,
      amount: finalAmount,
      baseAmount: amount,
      multiplier,
      type,
      timestamp: new Date().toISOString(),
      description,
      status: 'completed'
    };

    await updateDoc(userRef, {
      points: increment(finalAmount),
      totalEarned: increment(finalAmount),
      [`bonusPoints.${type}`]: increment(finalAmount),
      transactions: arrayUnion(transaction)
    });

    // Play success sound
    const audio = new Audio('/sounds/coin.mp3');
    audio.play().catch(() => {});

    return true;
  } catch (error) {
    console.error('Error adding points:', error);
    toast.error('Failed to add points');
    return false;
  }
}

// Deduct points from user account
export async function deductPoints(
  userId: string,
  amount: number,
  type: 'withdrawal' | 'purchase',
  description: string
): Promise<boolean> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    if (userData.isBanned) {
      throw new Error('Account is banned');
    }

    if (userData.points < amount) {
      throw new Error('Insufficient points');
    }

    const transaction = {
      id: `${type}-${Date.now()}`,
      amount: -amount,
      type,
      timestamp: new Date().toISOString(),
      description,
      status: 'completed'
    };

    await updateDoc(userRef, {
      points: increment(-amount),
      transactions: arrayUnion(transaction)
    });

    return true;
  } catch (error) {
    console.error('Error deducting points:', error);
    toast.error(error instanceof Error ? error.message : 'Failed to deduct points');
    return false;
  }
}