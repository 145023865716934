import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { ADMIN_EMAILS } from '../lib/constants';

export default function AdminRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-cyan-500"></div>
      </div>
    );
  }

  // Check if user is logged in and is an admin
  if (!user || !ADMIN_EMAILS.includes(user.email || '')) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}