import { useState } from 'react';
import { Search, Gift, Star, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import PageTransition from '../components/PageTransition';
import { useUser } from '../hooks/useUser';

const REWARDS = [
  {
    id: 1,
    title: 'Amazon Gift Card',
    description: '$10 Amazon.com Gift Card',
    points: 10000,
    image: 'https://images.unsplash.com/photo-1607083206968-13611e3d76db?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
    type: 'gift-card',
    processingTime: '24-48 hours'
  },
  {
    id: 2,
    title: 'PayPal Cash',
    description: '$25 PayPal transfer',
    points: 25000,
    image: 'https://images.unsplash.com/photo-1556742502-ec7c0e9f34b1?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
    type: 'cash',
    processingTime: '1-3 business days'
  },
  {
    id: 3,
    title: 'Steam Gift Card',
    description: '$20 Steam Wallet Code',
    points: 20000,
    image: 'https://images.unsplash.com/photo-1550745165-9bc0b252726f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
    type: 'gift-card',
    processingTime: 'Instant'
  },
  {
    id: 4,
    title: 'Google Play Gift Card',
    description: '$15 Google Play Credit',
    points: 15000,
    image: 'https://images.unsplash.com/photo-1592564630984-7410f94db184?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
    type: 'gift-card',
    processingTime: 'Instant'
  },
  {
    id: 5,
    title: 'Bitcoin Payout',
    description: '$50 in Bitcoin',
    points: 50000,
    image: 'https://images.unsplash.com/photo-1518546305927-5a555bb7020d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
    type: 'crypto',
    processingTime: '24 hours'
  },
  {
    id: 6,
    title: 'Apple App Store',
    description: '$20 App Store Credit',
    points: 20000,
    image: 'https://images.unsplash.com/photo-1585184394271-4c0a47dc59c9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3',
    type: 'gift-card',
    processingTime: 'Instant'
  }
];

const CATEGORIES = [
  { id: 'all', label: 'All Rewards' },
  { id: 'gift-card', label: 'Gift Cards' },
  { id: 'cash', label: 'Cash' },
  { id: 'crypto', label: 'Crypto' }
];

export default function Rewards() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const { user } = useUser();

  const filteredRewards = REWARDS.filter(reward => {
    const matchesSearch = reward.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reward.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || reward.type === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  return (
    <PageTransition>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4 sm:mb-0">
            Rewards Store
          </h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search rewards..."
              className="w-full sm:w-64 pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <div className="flex overflow-x-auto sm:overflow-visible space-x-4 pb-4 mb-8 scrollbar-hide">
          {CATEGORIES.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-4 py-2 rounded-full whitespace-nowrap ${
                selectedCategory === category.id
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
              }`}
            >
              {category.label}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredRewards.map((reward) => (
            <motion.div
              key={reward.id}
              whileHover={{ scale: 1.02 }}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
            >
              <div className="relative h-48">
                <img
                  src={reward.image}
                  alt={reward.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-4 right-4 bg-black/50 backdrop-blur-sm px-3 py-1 rounded-full">
                  <div className="flex items-center space-x-1">
                    <Star className="h-4 w-4 text-yellow-400" />
                    <span className="text-sm font-medium text-white">
                      {reward.points.toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                  {reward.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  {reward.description}
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                    <Clock className="h-4 w-4 mr-1" />
                    <span>{reward.processingTime}</span>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium ${
                      user?.points >= reward.points
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    }`}
                    disabled={!user || user.points < reward.points}
                  >
                    <Gift className="h-4 w-4 mr-2" />
                    Redeem
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </PageTransition>
  );
}