import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Wifi, WifiOff, Database } from 'lucide-react';
import { db } from '../lib/firebase';
import { enableNetwork, disableNetwork } from 'firebase/firestore';

export default function ConnectionStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isFirebaseConnected, setIsFirebaseConnected] = useState(true);
  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    const handleOnline = async () => {
      setIsOnline(true);
      setShowStatus(true);
      try {
        await enableNetwork(db);
        setIsFirebaseConnected(true);
        // Reload the page to refresh data
        window.location.reload();
      } catch (error) {
        console.error('Error enabling network:', error);
        setIsFirebaseConnected(false);
      }
      setTimeout(() => setShowStatus(false), 3000);
    };

    const handleOffline = async () => {
      setIsOnline(false);
      setShowStatus(true);
      try {
        await disableNetwork(db);
        setIsFirebaseConnected(false);
      } catch (error) {
        console.error('Error disabling network:', error);
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Check initial connection status
    if (!navigator.onLine) {
      handleOffline();
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!showStatus && isOnline && isFirebaseConnected) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        className={`fixed bottom-4 left-4 z-50 px-4 py-2 rounded-lg flex items-center space-x-2 ${
          !isOnline ? 'bg-red-500/20' :
          !isFirebaseConnected ? 'bg-yellow-500/20' :
          'bg-green-500/20'
        }`}
      >
        {!isOnline ? (
          <>
            <WifiOff className="h-4 w-4 text-red-400" />
            <span className="text-red-400">Offline Mode</span>
          </>
        ) : !isFirebaseConnected ? (
          <>
            <Database className="h-4 w-4 text-yellow-400" />
            <span className="text-yellow-400">Reconnecting...</span>
          </>
        ) : (
          <>
            <Wifi className="h-4 w-4 text-green-400" />
            <span className="text-green-400">Connected</span>
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
}