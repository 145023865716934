import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, X, Coins, Trophy, Gift, User, LogOut, Settings, Shield } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { auth } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { ADMIN_EMAILS } from '../lib/constants';
import ThemeToggle from './ThemeToggle';
import GamingButton from './GamingButton';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const isAdmin = user && ADMIN_EMAILS.includes(user.email || '');

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <nav className="relative z-50 border-b border-cyan-500/20 bg-gray-900/80 backdrop-blur-xl">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <motion.div 
            className="flex items-center"
            whileHover={{ scale: 1.05 }}
          >
            <Link to="/" className="flex items-center group">
              <motion.div
                animate={{ 
                  rotate: 360,
                  scale: [1, 1.2, 1]
                }}
                transition={{ 
                  rotate: { duration: 20, repeat: Infinity, ease: "linear" },
                  scale: { duration: 2, repeat: Infinity, ease: "easeInOut" }
                }}
                className="relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full blur-lg opacity-75 group-hover:opacity-100 transition-opacity" />
                <Coins className="h-8 w-8 text-yellow-400 relative z-10" />
              </motion.div>
              <span className="ml-2 text-xl font-cyber bg-gradient-to-r from-cyan-400 to-blue-400 bg-clip-text text-transparent">
                GainJourney
              </span>
            </Link>
          </motion.div>

          <div className="hidden md:flex md:items-center md:space-x-6">
            <Link 
              to="/leaderboard"
              className="flex items-center space-x-1 text-gray-300 hover:text-cyan-400 transition-colors"
            >
              <Trophy className="h-4 w-4" />
              <span>Leaderboard</span>
            </Link>

            {user ? (
              <>
                <Link 
                  to="/offers"
                  className="flex items-center space-x-1 text-gray-300 hover:text-cyan-400 transition-colors"
                >
                  <Gift className="h-4 w-4" />
                  <span>Offers</span>
                </Link>

                <Link 
                  to="/dashboard"
                  className="flex items-center space-x-1 text-gray-300 hover:text-cyan-400 transition-colors"
                >
                  <User className="h-4 w-4" />
                  <span>Dashboard</span>
                </Link>

                {isAdmin && (
                  <Link 
                    to="/admin"
                    className="flex items-center space-x-1 text-gray-300 hover:text-cyan-400 transition-colors"
                  >
                    <Shield className="h-4 w-4" />
                    <span>Admin</span>
                  </Link>
                )}

                <Link 
                  to="/profile"
                  className="flex items-center space-x-1 text-gray-300 hover:text-cyan-400 transition-colors"
                >
                  <Settings className="h-4 w-4" />
                  <span>Settings</span>
                </Link>

                <GamingButton
                  variant="secondary"
                  size="sm"
                  onClick={handleLogout}
                  className="ml-4"
                >
                  <LogOut className="h-4 w-4 mr-1" />
                  Sign Out
                </GamingButton>
              </>
            ) : (
              <div className="flex items-center space-x-4">
                <GamingButton
                  variant="secondary"
                  size="sm"
                >
                  <Link to="/login">Sign In</Link>
                </GamingButton>
                <GamingButton
                  variant="primary"
                  size="sm"
                  glowIntensity="high"
                >
                  <Link to="/login?signup=true">Sign Up</Link>
                </GamingButton>
              </div>
            )}
            
            <ThemeToggle />
          </div>

          <div className="md:hidden flex items-center space-x-2">
            <ThemeToggle />
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-lg text-gray-300 hover:text-cyan-400 transition-colors"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="md:hidden border-t border-cyan-500/20 bg-gray-900/95 backdrop-blur-xl"
          >
            <div className="px-4 pt-2 pb-3 space-y-2">
              <Link
                to="/leaderboard"
                className="flex items-center space-x-2 p-3 rounded-lg text-gray-300 hover:text-cyan-400 hover:bg-cyan-500/10 transition-colors"
                onClick={() => setIsOpen(false)}
              >
                <Trophy className="h-5 w-5" />
                <span>Leaderboard</span>
              </Link>

              {user ? (
                <>
                  <Link
                    to="/offers"
                    className="flex items-center space-x-2 p-3 rounded-lg text-gray-300 hover:text-cyan-400 hover:bg-cyan-500/10 transition-colors"
                    onClick={() => setIsOpen(false)}
                  >
                    <Gift className="h-5 w-5" />
                    <span>Offers</span>
                  </Link>

                  <Link
                    to="/dashboard"
                    className="flex items-center space-x-2 p-3 rounded-lg text-gray-300 hover:text-cyan-400 hover:bg-cyan-500/10 transition-colors"
                    onClick={() => setIsOpen(false)}
                  >
                    <User className="h-5 w-5" />
                    <span>Dashboard</span>
                  </Link>

                  {isAdmin && (
                    <Link
                      to="/admin"
                      className="flex items-center space-x-2 p-3 rounded-lg text-gray-300 hover:text-cyan-400 hover:bg-cyan-500/10 transition-colors"
                      onClick={() => setIsOpen(false)}
                    >
                      <Shield className="h-5 w-5" />
                      <span>Admin</span>
                    </Link>
                  )}

                  <Link
                    to="/profile"
                    className="flex items-center space-x-2 p-3 rounded-lg text-gray-300 hover:text-cyan-400 hover:bg-cyan-500/10 transition-colors"
                    onClick={() => setIsOpen(false)}
                  >
                    <Settings className="h-5 w-5" />
                    <span>Settings</span>
                  </Link>

                  <button
                    onClick={() => {
                      handleLogout();
                      setIsOpen(false);
                    }}
                    className="w-full flex items-center space-x-2 p-3 rounded-lg text-red-400 hover:bg-red-500/10 transition-colors"
                  >
                    <LogOut className="h-5 w-5" />
                    <span>Sign Out</span>
                  </button>
                </>
              ) : (
                <div className="space-y-2 p-3">
                  <GamingButton
                    variant="secondary"
                    className="w-full mb-2"
                    onClick={() => {
                      navigate('/login');
                      setIsOpen(false);
                    }}
                  >
                    Sign In
                  </GamingButton>
                  <GamingButton
                    variant="primary"
                    className="w-full"
                    onClick={() => {
                      navigate('/login?signup=true');
                      setIsOpen(false);
                    }}
                  >
                    Sign Up
                  </GamingButton>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}