import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';

export function useWelcomePopup() {
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    const checkWelcomeStatus = async () => {
      const authUser = auth.currentUser;
      if (!authUser) return;

      try {
        const userRef = doc(db, 'users', authUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (!userData.hasSeenWelcome) {
            setShowWelcome(true);
          }
        }
      } catch (error) {
        console.error('Error checking welcome status:', error);
      }
    };

    checkWelcomeStatus();
  }, []);

  const closeWelcome = async () => {
    const authUser = auth.currentUser;
    if (!authUser) return;

    try {
      const userRef = doc(db, 'users', authUser.uid);
      await setDoc(userRef, { hasSeenWelcome: true }, { merge: true });
      setShowWelcome(false);
    } catch (error) {
      console.error('Error updating welcome status:', error);
    }
  };

  return { showWelcome, closeWelcome };
}