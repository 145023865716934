import PageTransition from '../components/PageTransition';
import GamingHeading from '../components/GamingHeading';

export default function Privacy() {
  return (
    <PageTransition>
      <div className="min-h-screen bg-gray-900 py-16 px-4">
        <div className="max-w-4xl mx-auto prose prose-invert">
          <GamingHeading level={1} variant="gradient" className="text-4xl mb-8">
            Privacy Policy
          </GamingHeading>

          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">1. Information We Collect</h2>
              <div className="text-gray-400 space-y-2">
                <h3 className="text-xl text-white mt-4 mb-2">Personal Information</h3>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Email address</li>
                  <li>Username</li>
                  <li>Payment information</li>
                  <li>IP address</li>
                  <li>Device information</li>
                </ul>

                <h3 className="text-xl text-white mt-4 mb-2">Usage Information</h3>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Completed offers and surveys</li>
                  <li>Points earned and redeemed</li>
                  <li>Login times and activity</li>
                  <li>Referral activities</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">2. How We Use Your Information</h2>
              <div className="text-gray-400 space-y-2">
                <p>We use your information to:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Provide and maintain our services</li>
                  <li>Process your rewards and payments</li>
                  <li>Verify offer completions</li>
                  <li>Prevent fraud and abuse</li>
                  <li>Send important notifications</li>
                  <li>Improve our platform</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">3. Information Sharing</h2>
              <div className="text-gray-400 space-y-2">
                <p>We may share your information with:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Offer providers to track completions</li>
                  <li>Payment processors for rewards</li>
                  <li>Analytics services</li>
                  <li>Law enforcement when required</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">4. Data Security</h2>
              <div className="text-gray-400 space-y-2">
                <p>We protect your data through:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Encryption in transit and at rest</li>
                  <li>Regular security audits</li>
                  <li>Access controls and monitoring</li>
                  <li>Secure data centers</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">5. Your Rights</h2>
              <div className="text-gray-400 space-y-2">
                <p>You have the right to:</p>
                <ul className="list-disc list-inside space-y-1 ml-4">
                  <li>Access your personal data</li>
                  <li>Correct inaccurate data</li>
                  <li>Request data deletion</li>
                  <li>Opt-out of marketing communications</li>
                  <li>Export your data</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">6. Data Retention</h2>
              <p className="text-gray-400">
                We retain your personal information for as long as necessary to provide our services and comply with legal obligations. If you request account deletion, we will remove your personal information within 30 days.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">7. Children's Privacy</h2>
              <p className="text-gray-400">
                Our services are not intended for users under 18 years of age. We do not knowingly collect personal information from children. If we discover that we have collected personal information from a child, we will delete it immediately.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">8. Updates to Privacy Policy</h2>
              <p className="text-gray-400">
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-cyber text-cyan-400 mb-4">9. Contact Us</h2>
              <p className="text-gray-400">
                For any questions about this Privacy Policy, please contact us at privacy@gainjourney.com
              </p>
            </section>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}