// Mock data for the application
export const MOCK_LEADERBOARD = [
  { uid: 'user1', displayName: 'Sarah Johnson', points: 45000, completedOffers: 67, country: '🇺🇸', avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100' },
  { uid: 'user2', displayName: 'Michael Chen', points: 42500, completedOffers: 58, country: '🇨🇦', avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100' },
  { uid: 'user3', displayName: 'Emily Davis', points: 40000, completedOffers: 52, country: '🇬🇧', avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100' },
  { uid: 'user4', displayName: 'David Wilson', points: 37500, completedOffers: 49, country: '🇦🇺', avatar: 'https://images.unsplash.com/photo-1599566150163-29194dcaad36?w=100' },
  { uid: 'user5', displayName: 'Lisa Anderson', points: 35000, completedOffers: 45, country: '🇩🇪', avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100' },
  { uid: 'user6', displayName: 'James Lee', points: 32500, completedOffers: 42, country: '🇸🇬', avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100' },
  { uid: 'user7', displayName: 'Emma Thompson', points: 30000, completedOffers: 38, country: '🇳🇿', avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100' },
  { uid: 'user8', displayName: 'Alex Martinez', points: 27500, completedOffers: 35, country: '🇪🇸', avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100' },
  { uid: 'user9', displayName: 'Sophie Brown', points: 25000, completedOffers: 32, country: '🇫🇷', avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100' },
  { uid: 'user10', displayName: 'Ryan Kim', points: 22500, completedOffers: 28, country: '🇰🇷', avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100' }
];