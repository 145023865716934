// Admin configuration
export const ADMIN_EMAILS = ['ayoerlos@gmail.com'];

// Points configuration
export const POINTS_CONVERSION = {
  USD_TO_POINTS: 1000, // $1 = 1000 points
  POINTS_TO_USD: 0.001 // 1000 points = $1
};

// Withdrawal configuration
export const MIN_WITHDRAWAL = 15; // $15 minimum withdrawal

// Referral configuration
export const REFERRAL_BONUS = {
  REFERRER: 1000, // Points for referrer
  REFERRED: 1000  // Points for referred user
};