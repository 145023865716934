import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db, handleFirebaseError } from '../lib/firebase';
import { auth } from '../lib/firebase';
import type { User } from '../types';
import { toast } from 'react-hot-toast';

export function useUser() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const authUser = auth.currentUser;
    if (!authUser) {
      setUser(null);
      setLoading(false);
      return;
    }

    let unsubscribe: () => void;

    try {
      const userRef = doc(db, 'users', authUser.uid);
      unsubscribe = onSnapshot(
        userRef,
        (doc) => {
          if (doc.exists()) {
            setUser(doc.data() as User);
          } else {
            setError('User document not found');
            toast.error('User data not found. Please try logging in again.');
          }
          setLoading(false);
        },
        (err) => {
          console.error('User data error:', err);
          setError(err.message);
          handleFirebaseError(err);
          setLoading(false);
        }
      );
    } catch (err) {
      console.error('Setup error:', err);
      const message = err instanceof Error ? err.message : 'Failed to load user data';
      setError(message);
      handleFirebaseError(err);
      setLoading(false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return { user, loading, error };
}