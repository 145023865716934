import { useEffect, useRef } from 'react';
import { useAuth } from './useAuth';
import { updateLastActive } from '../lib/activity';

export function useActivity() {
  const { user } = useAuth();
  const lastUpdateRef = useRef<number>(0);
  const MIN_UPDATE_INTERVAL = 60000; // 1 minute minimum between updates

  useEffect(() => {
    if (!user) return;

    const shouldUpdate = () => {
      const now = Date.now();
      if (now - lastUpdateRef.current >= MIN_UPDATE_INTERVAL) {
        lastUpdateRef.current = now;
        return true;
      }
      return false;
    };

    // Update on mount
    if (shouldUpdate()) {
      updateLastActive(user.uid).catch(console.error); // Added error handling
    }

    // Set up interval to update every 5 minutes while active
    const interval = setInterval(() => {
      if (shouldUpdate()) {
        updateLastActive(user.uid).catch(console.error); // Added error handling
      }
    }, 5 * 60 * 1000);

    // Update on window focus
    const handleFocus = () => {
      if (shouldUpdate()) {
        updateLastActive(user.uid).catch(console.error); // Added error handling
      }
    };

    // Update on user interaction
    const handleActivity = () => {
      if (shouldUpdate()) {
        updateLastActive(user.uid).catch(console.error); // Added error handling
      }
    };

    window.addEventListener('focus', handleFocus);
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('click', handleActivity);
    document.addEventListener('touchstart', handleActivity);

    return () => {
      clearInterval(interval);
      window.removeEventListener('focus', handleFocus);
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('click', handleActivity);
      document.removeEventListener('touchstart', handleActivity);
    };
  }, [user]);
}