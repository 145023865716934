import { toast } from 'react-hot-toast';

export function validateEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email.trim());
}

export function validatePassword(password: string): boolean {
  return password.length >= 6;
}

export function validateName(name: string): boolean {
  const nameRegex = /^[a-zA-Z\s-]{2,}$/;
  return nameRegex.test(name.trim());
}

export function validateReferralCode(code: string): boolean {
  const codeRegex = /^[A-Z0-9]{6}$/;
  return code ? codeRegex.test(code.trim().toUpperCase()) : true;
}

export function validateSignupData(data: {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  country: string;
  referralCode?: string;
}): boolean {
  // Email validation
  if (!validateEmail(data.email)) {
    toast.error('Please enter a valid email address');
    return false;
  }

  // Password validation
  if (!validatePassword(data.password)) {
    toast.error('Password must be at least 6 characters');
    return false;
  }

  // Password confirmation
  if (data.password !== data.confirmPassword) {
    toast.error('Passwords do not match');
    return false;
  }

  // Name validation
  if (!validateName(data.name)) {
    toast.error('Please enter a valid name (letters, spaces, and hyphens only)');
    return false;
  }

  // Country validation
  if (!data.country) {
    toast.error('Please select your country');
    return false;
  }

  // Optional referral code validation
  if (data.referralCode && !validateReferralCode(data.referralCode)) {
    toast.error('Invalid referral code format');
    return false;
  }

  return true;
}