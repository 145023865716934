import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, DollarSign, CreditCard, AlertCircle } from 'lucide-react';
import { useUser } from '../hooks/useUser';
import { doc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import { Withdrawal } from '../types';

const MIN_WITHDRAWAL = 15; // $15 minimum withdrawal
const POINTS_PER_DOLLAR = 1000; // 1000 points = $1

interface WithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WITHDRAWAL_METHODS = [
  { id: 'paypal', name: 'PayPal', icon: DollarSign },
  { id: 'bitcoin', name: 'Bitcoin', icon: CreditCard },
  { id: 'amazon', name: 'Amazon Gift Card', icon: CreditCard },
];

export default function WithdrawModal({ isOpen, onClose }: WithdrawModalProps) {
  const { user } = useUser();
  const [amount, setAmount] = useState('');
  const [method, setMethod] = useState<'paypal' | 'bitcoin' | 'amazon'>('paypal');
  const [address, setAddress] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!user) return null;

  const maxDollars = Math.floor(user.points / POINTS_PER_DOLLAR);
  const pointsRequired = parseFloat(amount) * POINTS_PER_DOLLAR;
  const canWithdraw = user.points >= MIN_WITHDRAWAL * POINTS_PER_DOLLAR;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!amount || !method || !address || isSubmitting) return;

    const amountNum = parseFloat(amount);
    if (amountNum < MIN_WITHDRAWAL) {
      toast.error(`Minimum withdrawal amount is $${MIN_WITHDRAWAL}`);
      return;
    }

    if (pointsRequired > user.points) {
      toast.error('Insufficient points');
      return;
    }

    try {
      setIsSubmitting(true);
      const withdrawal: Withdrawal = {
        id: Math.random().toString(36).substring(2),
        amount: amountNum,
        pointsAmount: pointsRequired,
        method,
        status: 'pending',
        timestamp: new Date().toISOString(),
        address,
        email: method === 'paypal' ? address : undefined,
      };

      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        points: increment(-pointsRequired),
        withdrawals: arrayUnion(withdrawal),
      });

      toast.success('Withdrawal request submitted successfully!');
      onClose();
    } catch (error) {
      console.error('Error submitting withdrawal:', error);
      toast.error('Failed to submit withdrawal request');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md relative"
          >
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <X className="h-5 w-5" />
            </button>

            <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              Withdraw Funds
            </h2>

            {!canWithdraw ? (
              <div className="flex items-center p-4 bg-yellow-50 dark:bg-yellow-900/30 rounded-lg">
                <AlertCircle className="h-5 w-5 text-yellow-600 dark:text-yellow-400 mr-2" />
                <p className="text-sm text-yellow-600 dark:text-yellow-400">
                  You need at least {MIN_WITHDRAWAL * POINTS_PER_DOLLAR} points (${MIN_WITHDRAWAL}) to withdraw.
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Amount (USD)
                  </label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    <input
                      type="number"
                      min={MIN_WITHDRAWAL}
                      max={maxDollars}
                      step="0.01"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="pl-10 w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                      placeholder={`Min $${MIN_WITHDRAWAL}, Max $${maxDollars}`}
                      required
                    />
                  </div>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Points required: {pointsRequired.toLocaleString()}
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Withdrawal Method
                  </label>
                  <div className="grid grid-cols-3 gap-2">
                    {WITHDRAWAL_METHODS.map((m) => (
                      <button
                        key={m.id}
                        type="button"
                        onClick={() => setMethod(m.id as typeof method)}
                        className={`p-3 rounded-lg border ${
                          method === m.id
                            ? 'border-indigo-500 bg-indigo-50 dark:bg-indigo-900/30'
                            : 'border-gray-300 dark:border-gray-600'
                        } flex flex-col items-center justify-center gap-1`}
                      >
                        <m.icon className="h-5 w-5 text-gray-600 dark:text-gray-300" />
                        <span className="text-xs text-gray-600 dark:text-gray-300">
                          {m.name}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    {method === 'paypal' ? 'PayPal Email' : 'Wallet Address'}
                  </label>
                  <input
                    type={method === 'paypal' ? 'email' : 'text'}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                    placeholder={method === 'paypal' ? 'Enter PayPal email' : 'Enter wallet address'}
                    required
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting || !amount || !method || !address}
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  {isSubmitting ? 'Processing...' : 'Submit Withdrawal'}
                </button>
              </form>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}