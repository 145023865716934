import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Gift, RotateCw, Lock, Sparkles, Star, Clock, Crown, Flame } from 'lucide-react';
import { useUser } from '../hooks/useUser';
import { usePoints } from '../hooks/usePoints';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';
import RewardPopup from './RewardPopup';

const REWARDS = [
  { points: 100, probability: 0.3, color: 'from-blue-500 to-blue-600', icon: '🎁' },
  { points: 200, probability: 0.25, color: 'from-green-500 to-green-600', icon: '💎' },
  { points: 300, probability: 0.2, color: 'from-yellow-500 to-yellow-600', icon: '⭐' },
  { points: 500, probability: 0.15, color: 'from-purple-500 to-purple-600', icon: '🌟' },
  { points: 1000, probability: 0.07, color: 'from-pink-500 to-pink-600', icon: '👑' },
  { points: 2000, probability: 0.03, color: 'from-red-500 to-red-600', icon: '🏆' },
];

const TOTAL_SEGMENTS = REWARDS.length;
const SEGMENT_DEGREE = 360 / TOTAL_SEGMENTS;
const SPIN_DURATION = 5; // seconds

interface SpinResult {
  points: number;
  icon: string;
  color: string;
}

export default function DailySpin() {
  const { user } = useUser();
  const { addPoints } = usePoints();
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [canSpin, setCanSpin] = useState(true);
  const [showWinModal, setShowWinModal] = useState(false);
  const [winAmount, setWinAmount] = useState(0);
  const [streakBonus, setStreakBonus] = useState(0);
  const spinButtonRef = useRef<HTMLButtonElement>(null);
  const wheelRef = useRef<HTMLDivElement>(null);
  const [selectedReward, setSelectedReward] = useState<typeof REWARDS[0] | null>(null);

  useEffect(() => {
    if (!user) return;

    const lastSpin = user.lastSpinDate ? new Date(user.lastSpinDate) : null;
    const now = new Date();
    
    if (lastSpin) {
      const lastSpinDay = new Date(lastSpin);
      lastSpinDay.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const canSpinToday = lastSpinDay.getTime() < today.getTime();
      setCanSpin(canSpinToday);
    }
  }, [user]);

  const getRandomReward = (): SpinResult => {
    const random = Math.random();
    let cumulativeProbability = 0;
    
    for (const reward of REWARDS) {
      cumulativeProbability += reward.probability;
      if (random <= cumulativeProbability) {
        return {
          points: reward.points,
          icon: reward.icon,
          color: reward.color
        };
      }
    }
    
    // Fallback to first reward if something goes wrong
    return {
      points: REWARDS[0].points,
      icon: REWARDS[0].icon,
      color: REWARDS[0].color
    };
  };

  const spin = async () => {
    if (!user || !canSpin || isSpinning) return;

    try {
      setIsSpinning(true);
      const reward = getRandomReward();
      setSelectedReward(REWARDS.find(r => r.points === reward.points) || null);
      
      // Calculate final rotation to match the selected reward
      const rewardIndex = REWARDS.findIndex(r => r.points === reward.points);
      const segmentRotation = SEGMENT_DEGREE * rewardIndex;
      const randomOffset = Math.random() * (SEGMENT_DEGREE * 0.8);
      const spins = 5; // Number of full rotations
      const finalRotation = (spins * 360) + segmentRotation + randomOffset;

      setRotation(finalRotation);

      // Calculate streak bonus
      const streakMultiplier = Math.min((user.streakDays || 0) * 0.1, 1); // Max 100% bonus
      const bonusPoints = Math.floor(reward.points * streakMultiplier);

      // Wait for animation to complete
      setTimeout(async () => {
        try {
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            lastSpinDate: new Date().toISOString(),
          });

          await addPoints(reward.points + bonusPoints, 'daily', `Lucky Spin Reward: ${reward.points} points + ${bonusPoints} streak bonus`);
          setWinAmount(reward.points);
          setStreakBonus(bonusPoints);
          setShowWinModal(true);

          // Play success sound
          const audio = new Audio('/sounds/coin.mp3');
          audio.play().catch(() => {});
        } catch (error) {
          console.error('Error updating spin reward:', error);
          toast.error('Failed to claim reward. Please try again.');
        } finally {
          setIsSpinning(false);
          setCanSpin(false);
          setSelectedReward(null);
        }
      }, SPIN_DURATION * 1000);
    } catch (error) {
      console.error('Error during spin:', error);
      toast.error('Something went wrong. Please try again.');
      setIsSpinning(false);
    }
  };

  if (!user) return null;

  return (
    <GamingCard className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-bold text-white flex items-center">
          <Star className="h-5 w-5 mr-2 text-yellow-400 animate-pulse" />
          Lucky Spin
        </h3>
        <div className="flex items-center space-x-2">
          <Sparkles className="h-5 w-5 text-yellow-400 animate-pulse" />
          <span className="text-yellow-400 font-medium">Win up to 2000 points!</span>
        </div>
      </div>

      <div className="relative">
        {/* Wheel Container with Glow Effect */}
        <div className="relative w-72 h-72 mx-auto">
          {/* Pointer */}
          <div className="absolute top-0 left-1/2 -translate-x-1/2 -mt-4 w-8 h-8 z-10">
            <motion.div
              animate={{ y: [0, -4, 0] }}
              transition={{ duration: 1, repeat: Infinity }}
              className="w-8 h-8 bg-gradient-to-br from-yellow-400 to-yellow-600 rotate-45 transform origin-bottom shadow-lg"
            />
          </div>

          {/* Wheel with Metallic Effect */}
          <motion.div
            ref={wheelRef}
            animate={{ rotate: rotation }}
            transition={{ 
              duration: SPIN_DURATION,
              ease: [0.2, 0.6, 0.3, 1],
            }}
            className="w-full h-full rounded-full border-8 border-yellow-400/50 relative overflow-hidden shadow-[0_0_50px_rgba(234,179,8,0.3)] bg-gray-800"
            style={{ transformOrigin: "center center" }}
          >
            {REWARDS.map((reward, index) => {
              const rotation = (index * SEGMENT_DEGREE);
              const isSelected = selectedReward?.points === reward.points;
              
              return (
                <div
                  key={index}
                  className="absolute w-full h-full"
                  style={{
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: "50% 50%",
                    clipPath: "polygon(50% 50%, 50% 0%, 100% 0%, 100% 50%)",
                  }}
                >
                  <div className={`w-full h-full bg-gradient-to-r ${reward.color} flex items-center justify-center relative overflow-hidden ${
                    isSelected ? 'animate-pulse' : ''
                  }`}>
                    <div className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,0.1)_50%,transparent_75%)]"></div>
                    <div
                      className="text-white font-bold transform text-2xl shadow-lg flex flex-col items-center"
                      style={{ transform: `rotate(${-rotation - 90}deg) translateX(5rem)` }}
                    >
                      <span className="text-3xl mb-1">{reward.icon}</span>
                      <span>{reward.points}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </motion.div>
        </div>

        {/* Spin Button */}
        <GamingButton
          ref={spinButtonRef}
          onClick={spin}
          disabled={!canSpin || isSpinning}
          variant="primary"
          className="w-full mt-8"
          glowIntensity="high"
        >
          {isSpinning ? (
            <RotateCw className="h-6 w-6 animate-spin" />
          ) : canSpin ? (
            <>
              <Gift className="h-6 w-6 mr-2" />
              SPIN NOW!
            </>
          ) : (
            <>
              <Lock className="h-6 w-6 mr-2" />
              Return Tomorrow
            </>
          )}
        </GamingButton>
      </div>

      {/* Win Modal */}
      <RewardPopup
        isOpen={showWinModal}
        onClose={() => setShowWinModal(false)}
        title="Congratulations!"
        points={winAmount}
        message="You won from the Lucky Spin!"
        streakBonus={streakBonus}
        icon="🎰"
      />

      {/* Rewards List */}
      <div className="mt-8 space-y-3">
        <p className="text-lg font-bold text-white flex items-center">
          <Gift className="h-5 w-5 mr-2 text-yellow-400" />
          Possible Rewards
        </p>
        <div className="grid grid-cols-2 gap-3">
          {REWARDS.map((reward, index) => (
            <div
              key={index}
              className={`bg-gradient-to-r ${reward.color} p-3 rounded-lg shadow-lg relative overflow-hidden group transition-transform duration-300 hover:scale-105`}
            >
              <div className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,0.1)_50%,transparent_75%)]"></div>
              <div className="relative flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <span className="text-2xl">{reward.icon}</span>
                  <span className="text-white font-bold">
                    {reward.points} points
                  </span>
                </div>
                <span className="text-white/75 text-sm">
                  {(reward.probability * 100).toFixed(1)}%
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Streak Info */}
      {user.streakDays > 0 && (
        <div className="mt-6 bg-gradient-to-r from-orange-500/20 to-red-500/20 rounded-lg p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Flame className="h-5 w-5 text-orange-400" />
              <span className="text-orange-400 font-medium">
                {user.streakDays} Day Streak
              </span>
            </div>
            <span className="text-orange-400">
              +{Math.min(user.streakDays * 10, 100)}% Bonus
            </span>
          </div>
        </div>
      )}
    </GamingCard>
  );
}