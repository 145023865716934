import { useState, useEffect } from 'react';
import { MOCK_LEADERBOARD } from '../data/mockData';

export function useLeaderboard(limitCount = 10) {
  const [leaders, setLeaders] = useState(MOCK_LEADERBOARD);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Simulate API loading
    setLoading(true);
    setTimeout(() => {
      setLeaders(MOCK_LEADERBOARD.slice(0, limitCount));
      setLoading(false);
    }, 1000);
  }, [limitCount]);

  return { leaders, loading };
}