import { useState } from 'react';
import { motion } from 'framer-motion';
import { Activity, Target, Award, Zap, DollarSign, ChevronLeft, ChevronRight } from 'lucide-react';
import { useUser } from '../hooks/useUser';
import { Transaction } from '../types';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';

const ITEMS_PER_PAGE = 5;

export default function RecentActivity() {
  const { user } = useUser();
  const [page, setPage] = useState(0);

  if (!user?.transactions) return null;

  const sortedTransactions = [...user.transactions].sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  const totalPages = Math.ceil(sortedTransactions.length / ITEMS_PER_PAGE);
  const currentTransactions = sortedTransactions.slice(
    page * ITEMS_PER_PAGE,
    (page + 1) * ITEMS_PER_PAGE
  );

  const getIcon = (type: Transaction['type']) => {
    switch (type) {
      case 'offer':
        return Target;
      case 'achievement':
        return Award;
      case 'daily':
      case 'streak':
        return Zap;
      case 'withdrawal':
        return DollarSign;
      default:
        return Activity;
    }
  };

  const getStatusColor = (status?: string) => {
    switch (status) {
      case 'completed':
        return 'text-green-400';
      case 'pending':
        return 'text-yellow-400';
      case 'failed':
        return 'text-red-400';
      default:
        return 'text-green-400';
    }
  };

  return (
    <GamingCard className="p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-white flex items-center">
          <Activity className="h-5 w-5 mr-2 text-cyan-400" />
          Recent Activity
        </h3>
        <div className="text-sm text-gray-400">
          Showing {page * ITEMS_PER_PAGE + 1}-{Math.min((page + 1) * ITEMS_PER_PAGE, sortedTransactions.length)} of {sortedTransactions.length}
        </div>
      </div>

      <div className="space-y-4">
        {currentTransactions.map((transaction, index) => {
          const TransactionIcon = getIcon(transaction.type);
          const isPositive = transaction.type !== 'withdrawal';
          
          return (
            <motion.div
              key={transaction.id}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center justify-between p-4 rounded-lg bg-gray-800/50 border border-cyan-500/10"
            >
              <div className="flex items-center">
                <div className="h-10 w-10 rounded-lg bg-cyan-500/20 flex items-center justify-center">
                  <TransactionIcon className="h-5 w-5 text-cyan-400" />
                </div>
                <div className="ml-4">
                  <p className="text-sm font-medium text-white">
                    {transaction.description}
                  </p>
                  <p className="text-xs text-gray-400">
                    {new Date(transaction.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <span className={`text-sm font-semibold ${getStatusColor(transaction.status)}`}>
                  {isPositive ? '+' : '-'}{transaction.amount.toLocaleString()} pts
                </span>
                {transaction.status && (
                  <p className={`text-xs ${getStatusColor(transaction.status)}`}>
                    {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                  </p>
                )}
              </div>
            </motion.div>
          );
        })}
      </div>

      {totalPages > 1 && (
        <div className="flex items-center justify-between mt-6">
          <GamingButton
            variant="secondary"
            size="sm"
            onClick={() => setPage(Math.max(0, page - 1))}
            disabled={page === 0}
          >
            <ChevronLeft className="h-4 w-4 mr-1" />
            Previous
          </GamingButton>
          
          <span className="text-sm text-gray-400">
            Page {page + 1} of {totalPages}
          </span>
          
          <GamingButton
            variant="secondary"
            size="sm"
            onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
            disabled={page === totalPages - 1}
          >
            Next
            <ChevronRight className="h-4 w-4 ml-1" />
          </GamingButton>
        </div>
      )}
    </GamingCard>
  );
}