import { useState, useRef } from 'react';
import { Award, Gift, DollarSign, Users, TrendingUp, Clock, Target, Zap, Activity, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import DailyRewards from '../components/DailyRewards';
import DailySpin from '../components/DailySpin';
import ReferralStats from '../components/ReferralStats';
import DailyTasks from '../components/DailyTasks';
import PointsDisplay from '../components/PointsDisplay';
import UserLevel from '../components/UserLevel';
import EarningsChart from '../components/EarningsChart';
import RecentActivity from '../components/RecentActivity';
import WithdrawModal from '../components/WithdrawModal';
import PageTransition from '../components/PageTransition';
import NotificationBar from '../components/NotificationBar';
import WelcomePopup from '../components/WelcomePopup';
import { useWelcomePopup } from '../hooks/useWelcomePopup';
import { useUser } from '../hooks/useUser';
import GamingButton from '../components/GamingButton';
import GamingHeading from '../components/GamingHeading';

export default function Dashboard() {
  const { user } = useUser();
  const { showWelcome, closeWelcome } = useWelcomePopup();
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [showQuickActions, setShowQuickActions] = useState(false);

  // Refs for scrolling
  const dailyRewardsRef = useRef<HTMLDivElement>(null);
  const luckySpinRef = useRef<HTMLDivElement>(null);
  const watchAdsRef = useRef<HTMLDivElement>(null);

  if (!user) return null;

  const quickActions = [
    {
      title: 'Daily Rewards',
      icon: Gift,
      color: 'text-green-400',
      onClick: () => {
        dailyRewardsRef.current?.scrollIntoView({ behavior: 'smooth' });
        setShowQuickActions(false);
      }
    },
    {
      title: 'Lucky Spin',
      icon: Target,
      color: 'text-yellow-400',
      onClick: () => {
        luckySpinRef.current?.scrollIntoView({ behavior: 'smooth' });
        setShowQuickActions(false);
      }
    },
    {
      title: 'Daily Tasks',
      icon: Activity,
      color: 'text-purple-400',
      onClick: () => {
        watchAdsRef.current?.scrollIntoView({ behavior: 'smooth' });
        setShowQuickActions(false);
      }
    }
  ];

  const stats = [
    { label: 'Completed Offers', value: user.completedOffers || 0, icon: Target },
    { label: 'Achievement Points', value: user.achievements?.length * 500 || 0, icon: Award },
    { label: 'Referral Earnings', value: user.bonusPoints?.referral || 0, icon: Users },
    { label: 'Streak Bonus', value: `${Math.min((user.streakDays || 0) * 0.1, 1)}x`, icon: Zap },
  ];

  return (
    <PageTransition>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Welcome Popup */}
        <WelcomePopup isOpen={showWelcome} onClose={closeWelcome} />

        {/* Header with Points Overview */}
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4">
            <div>
              <GamingHeading level={1} variant="gradient" className="text-3xl mb-2">
                Welcome Back, {user.displayName}!
              </GamingHeading>
              <p className="text-gray-400">
                Track your progress and earn rewards
              </p>
            </div>
            <div className="mt-4 sm:mt-0 flex items-center space-x-3">
              <div className="relative">
                <GamingButton
                  variant="secondary"
                  onClick={() => setShowQuickActions(!showQuickActions)}
                >
                  Quick Actions
                  <ChevronDown className={`ml-2 h-4 w-4 transition-transform ${showQuickActions ? 'rotate-180' : ''}`} />
                </GamingButton>

                <AnimatePresence>
                  {showQuickActions && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-lg shadow-xl border border-cyan-500/20 overflow-hidden z-50"
                    >
                      {quickActions.map((action, index) => {
                        const Icon = action.icon;
                        return (
                          <motion.button
                            key={action.title}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: index * 0.1 }}
                            onClick={action.onClick}
                            className="w-full flex items-center space-x-3 p-4 hover:bg-gray-700/50 transition-colors"
                          >
                            <Icon className={`h-5 w-5 ${action.color}`} />
                            <span className="text-white">{action.title}</span>
                          </motion.button>
                        );
                      })}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <GamingButton
                variant="primary"
                onClick={() => setIsWithdrawModalOpen(true)}
                glowIntensity="high"
              >
                <DollarSign className="h-5 w-5 mr-2" />
                Withdraw Funds
              </GamingButton>
            </div>
          </div>

          <PointsDisplay
            refs={{
              dailyRewards: dailyRewardsRef,
              luckySpin: luckySpinRef,
              watchAds: watchAdsRef
            }}
          />
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.02 }}
              className="bg-gray-800 rounded-lg p-6 border border-cyan-500/20"
            >
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-400">{stat.label}</p>
                  <p className="text-2xl font-bold text-white mt-1">
                    {stat.value.toString()}
                  </p>
                </div>
                <stat.icon className="h-8 w-8 text-cyan-400" />
              </div>
            </motion.div>
          ))}
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-8">
            <div ref={watchAdsRef}>
              <GamingHeading level={2} variant="gradient" className="text-2xl mb-4">
                Daily Tasks
              </GamingHeading>
              <DailyTasks />
            </div>

            <div ref={dailyRewardsRef}>
              <GamingHeading level={2} variant="gradient" className="text-2xl mb-4">
                Daily Rewards
              </GamingHeading>
              <DailyRewards />
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-8">
            <div ref={luckySpinRef}>
              <GamingHeading level={2} variant="gradient" className="text-2xl mb-4">
                Lucky Spin
              </GamingHeading>
              <DailySpin />
            </div>

            <div>
              <GamingHeading level={2} variant="gradient" className="text-2xl mb-4">
                Referral Stats
              </GamingHeading>
              <ReferralStats />
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-8 space-y-8">
          <div>
            <GamingHeading level={2} variant="gradient" className="text-2xl mb-4">
              Level Progress
            </GamingHeading>
            <UserLevel points={user.points} />
          </div>

          <div>
            <GamingHeading level={2} variant="gradient" className="text-2xl mb-4">
              Recent Activity
            </GamingHeading>
            <RecentActivity />
          </div>
        </div>

        {/* Modals */}
        <WithdrawModal
          isOpen={isWithdrawModalOpen}
          onClose={() => setIsWithdrawModalOpen(false)}
        />

        {/* Notification Bar */}
        <NotificationBar />
      </div>
    </PageTransition>
  );
}