import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, Star, X, Trophy, Flame } from 'lucide-react';
import GamingButton from './GamingButton';

interface RewardPopupProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  points: number;
  message?: string;
  streakDays?: number;
  streakBonus?: number;
  milestoneBonus?: number;
  icon?: string;
}

export default function RewardPopup({
  isOpen,
  onClose,
  title,
  points,
  message,
  streakDays,
  streakBonus,
  milestoneBonus,
  icon
}: RewardPopupProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/70"
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            className="relative max-w-md w-full mx-4 bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-xl shadow-2xl border border-cyan-500/20 overflow-hidden"
          >
            {/* Background Effects */}
            <div className="absolute inset-0 bg-gradient-to-br from-cyan-500/10 to-blue-500/10" />
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_0%,rgba(0,242,254,0.1),transparent)]" />

            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            {/* Content */}
            <div className="relative text-center">
              {/* Animated Icon */}
              <motion.div
                animate={{
                  rotate: [0, 15, -15, 0],
                  scale: [1, 1.2, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
                className="mx-auto mb-6 text-4xl"
              >
                {icon || <Sparkles className="h-12 w-12 text-cyan-400" />}
              </motion.div>

              {/* Title */}
              <h2 className="text-2xl font-bold text-white mb-4">
                {title}
              </h2>

              {/* Points */}
              <div className="mb-6">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 10,
                  }}
                  className="inline-flex items-center space-x-2 bg-gradient-to-r from-cyan-500 to-blue-500 px-6 py-3 rounded-full"
                >
                  <Star className="h-6 w-6 text-white" />
                  <span className="text-3xl font-bold text-white">
                    +{points.toLocaleString()}
                  </span>
                </motion.div>
              </div>

              {/* Streak Info */}
              {streakDays && streakDays > 1 && (
                <div className="mb-4 bg-orange-500/10 rounded-lg p-3">
                  <div className="flex items-center justify-center space-x-2">
                    <Flame className="h-5 w-5 text-orange-400" />
                    <p className="text-orange-400">
                      {streakDays} Day Streak!
                    </p>
                  </div>
                </div>
              )}

              {/* Bonuses */}
              {(streakBonus || milestoneBonus) && (
                <div className="space-y-2 mb-4">
                  {streakBonus > 0 && (
                    <div className="bg-cyan-500/10 rounded-lg p-3">
                      <div className="flex items-center justify-center space-x-2">
                        <Sparkles className="h-5 w-5 text-cyan-400" />
                        <p className="text-cyan-400">
                          +{streakBonus.toLocaleString()} Streak Bonus!
                        </p>
                      </div>
                    </div>
                  )}
                  {milestoneBonus > 0 && (
                    <div className="bg-purple-500/10 rounded-lg p-3">
                      <div className="flex items-center justify-center space-x-2">
                        <Trophy className="h-5 w-5 text-purple-400" />
                        <p className="text-purple-400">
                          +{milestoneBonus.toLocaleString()} Milestone Bonus!
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Message */}
              {message && (
                <p className="text-gray-300 mb-6">
                  {message}
                </p>
              )}

              {/* Close Button */}
              <GamingButton
                variant="primary"
                onClick={onClose}
                className="w-full"
                glowIntensity="high"
              >
                Awesome!
              </GamingButton>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}