import { Component, ErrorInfo, ReactNode } from 'react';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';
import { AlertCircle, RefreshCw } from 'lucide-react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen flex items-center justify-center p-4">
          <GamingCard className="p-6 max-w-md w-full">
            <div className="flex flex-col items-center text-center">
              <AlertCircle className="h-12 w-12 text-red-400 mb-4" />
              <h2 className="text-xl font-bold text-white mb-2">Something went wrong</h2>
              <p className="text-gray-400 mb-6">
                {this.state.error?.message || 'An unexpected error occurred'}
              </p>
              <GamingButton
                variant="primary"
                onClick={() => window.location.reload()}
              >
                <RefreshCw className="h-4 w-4 mr-2" />
                Reload Page
              </GamingButton>
            </div>
          </GamingCard>
        </div>
      );
    }

    return this.props.children;
  }
}