import { motion } from 'framer-motion';
import { ReactNode } from 'react';

interface GamingHeadingProps {
  children: ReactNode;
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  variant?: 'gradient' | 'glow' | 'cyber';
}

export default function GamingHeading({
  children,
  className = '',
  level = 1,
  variant = 'gradient'
}: GamingHeadingProps) {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  const variantStyles = {
    gradient: 'bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 to-blue-400',
    glow: 'text-cyan-400 drop-shadow-[0_0_10px_rgba(0,242,254,0.5)]',
    cyber: 'text-cyan-400 border-b-2 border-cyan-500'
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`
        font-cyber
        tracking-wider
        ${variantStyles[variant]}
        ${className}
      `}
    >
      <Tag>{children}</Tag>
    </motion.div>
  );
}