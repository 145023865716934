import { Link } from 'react-router-dom';
import { Mail, Heart } from 'lucide-react';
import GamingButton from './GamingButton';

const FOOTER_LINKS = {
  legal: [
    { name: 'Terms of Service', href: '/terms' },
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Cookie Policy', href: '/cookies' }
  ],
  support: [
    { name: 'FAQ', href: '/faq' },
    { name: 'Contact Us', href: '/contact' }
  ]
};

export default function Footer() {
  return (
    <footer className="relative z-10 border-t border-cyan-500/20 bg-gray-900/80 backdrop-blur-xl">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Brand */}
          <div className="space-y-4">
            <Link to="/" className="flex items-center space-x-2">
              <span className="text-2xl font-cyber bg-gradient-to-r from-cyan-400 to-blue-400 bg-clip-text text-transparent">
                GainJourney
              </span>
            </Link>
            <p className="text-gray-400 text-sm">
              Complete offers, earn points, get instant rewards. Join our community today!
            </p>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-white font-cyber mb-4">Legal</h3>
            <ul className="space-y-2">
              {FOOTER_LINKS.legal.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.href}
                    className="text-gray-400 hover:text-cyan-400 transition-colors text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Support Links */}
          <div>
            <h3 className="text-white font-cyber mb-4">Support</h3>
            <ul className="space-y-2">
              {FOOTER_LINKS.support.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.href}
                    className="text-gray-400 hover:text-cyan-400 transition-colors text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-white font-cyber mb-4">Contact Us</h3>
            <div className="space-y-2">
              <a
                href="mailto:support@gainjourney.com"
                className="text-gray-400 hover:text-cyan-400 transition-colors flex items-center space-x-2"
              >
                <Mail className="h-4 w-4" />
                <span>support@gainjourney.com</span>
              </a>
              <p className="text-gray-400 text-sm">
                Business inquiries:<br />
                business@gainjourney.com
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-12 pt-8 border-t border-cyan-500/20">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex items-center space-x-1 text-gray-400 text-sm">
              <span>© 2024 GainJourney. All rights reserved.</span>
            </div>

            <div className="flex items-center space-x-2">
              <a
                href="mailto:support@gainjourney.com"
                className="text-gray-400 hover:text-cyan-400 transition-colors flex items-center space-x-1"
              >
                <Mail className="h-4 w-4" />
                <span className="text-sm">Contact Support</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}