import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { DollarSign, Star, X } from 'lucide-react';

// Mock data for notifications
const MOCK_NOTIFICATIONS = [
  {
    id: 1,
    type: 'withdrawal',
    message: 'John D. withdrew $25 via PayPal',
    timestamp: new Date(Date.now() - 1000 * 60 * 5).toISOString() // 5 minutes ago
  },
  {
    id: 2,
    type: 'points',
    message: 'Emma S. earned 500 points from surveys',
    timestamp: new Date(Date.now() - 1000 * 60 * 2).toISOString() // 2 minutes ago
  },
  {
    id: 3,
    type: 'withdrawal',
    message: 'Mike R. withdrew $50 in Bitcoin',
    timestamp: new Date(Date.now() - 1000 * 60 * 8).toISOString() // 8 minutes ago
  }
];

export default function NotificationBar() {
  const [currentNotification, setCurrentNotification] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentNotification((prev) => (prev + 1) % MOCK_NOTIFICATIONS.length);
        setIsVisible(true);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const notification = MOCK_NOTIFICATIONS[currentNotification];

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            className="bg-gray-800 border border-cyan-500/20 rounded-lg shadow-lg p-4 max-w-sm"
          >
            <div className="flex items-center space-x-3">
              <div className={`p-2 rounded-lg ${
                notification.type === 'withdrawal' 
                  ? 'bg-green-500/20' 
                  : 'bg-cyan-500/20'
              }`}>
                {notification.type === 'withdrawal' ? (
                  <DollarSign className="h-4 w-4 text-green-400" />
                ) : (
                  <Star className="h-4 w-4 text-cyan-400" />
                )}
              </div>
              <div className="flex-1">
                <p className="text-sm text-white">{notification.message}</p>
                <p className="text-xs text-gray-400">
                  {new Date(notification.timestamp).toLocaleTimeString()}
                </p>
              </div>
              <button 
                onClick={() => setIsVisible(false)}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}