import { toast } from 'react-hot-toast';

const API_KEY_OFFERS = 'e3bbd6fcd31256124dd96b697680da4d';
const USER_ID = '126330';

interface AdblumediaOffer {
  url: string;
  anchor: string;
  conversion: string;
  offer_id: string;
}

function jsonp<T>(url: string): Promise<T> {
  return new Promise((resolve, reject) => {
    const callbackName = 'jsonp_' + Math.random().toString(36).substring(2, 9);
    let script: HTMLScriptElement | null = document.createElement('script');
    let timeout: NodeJS.Timeout;

    (window as any)[callbackName] = (data: T) => {
      cleanup();
      resolve(data);
    };

    const cleanup = () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
      delete (window as any)[callbackName];
      if (timeout) clearTimeout(timeout);
      script = null;
    };

    timeout = setTimeout(() => {
      cleanup();
      reject(new Error('Request timed out'));
    }, 20000);

    script.onerror = () => {
      cleanup();
      reject(new Error('Failed to load offers'));
    };

    const sep = url.includes('?') ? '&' : '?';
    script.src = `${url}${sep}callback=${callbackName}&_=${Date.now()}`;
    document.head.appendChild(script);
  });
}

export async function fetchOffers(): Promise<any[]> {
  try {
    const adblumediaUrl = `https://du002iv2rxh4h.cloudfront.net/public/offers/feed.php?user_id=${USER_ID}&api_key=${API_KEY_OFFERS}`;
    const adblumediaData = await jsonp<AdblumediaOffer[]>(adblumediaUrl);

    if (!Array.isArray(adblumediaData)) {
      throw new Error('Invalid offers received');
    }

    // Process all offers
    const processedOffers = adblumediaData.map((offer, index) => ({
      id: `adblumedia-${offer.offer_id || `${Date.now()}-${index}`}`,
      title: offer.anchor,
      description: offer.conversion,
      url: offer.url,
      points: Math.floor(Math.random() * (800 - 300 + 1)) + 300,
      network: 'adblumedia' as const,
      category: offer.conversion.toLowerCase().includes('survey') ? 'surveys' as const : 'tasks' as const,
      estimatedTime: Math.floor(Math.random() * 15) + 5,
      image: `https://source.unsplash.com/random/800x600?${encodeURIComponent(offer.anchor.split(' ')[0])}`,
      payout: Math.random() * 2 + 1
    }));

    // Sort offers by points (highest first)
    return processedOffers.sort((a, b) => b.points - a.points);
  } catch (error) {
    console.error('Error fetching offers:', error);
    throw new Error('Failed to load offers. Please try again later.');
  }
}

export async function checkLeads(): Promise<any[]> {
  try {
    const response = await jsonp<any>(
      'https://du002iv2rxh4h.cloudfront.net/public/external/check2.php?testing=0'
    );
    return Array.isArray(response) ? response : [];
  } catch (error) {
    console.error('Error checking leads:', error);
    return [];
  }
}