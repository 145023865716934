import { useState, useEffect } from 'react';
import { fetchOffers } from '../lib/api';
import type { Offer } from '../types';
import { toast } from 'react-hot-toast';

export function useOffers() {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadOffers = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await fetchOffers();
        setOffers(data);
      } catch (err) {
        const message = err instanceof Error ? err.message : 'Failed to load offers';
        setError(message);
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    loadOffers();

    // Refresh offers every 5 minutes
    const interval = setInterval(loadOffers, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const retryLoading = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await fetchOffers();
      setOffers(data);
      toast.success('Offers refreshed successfully!');
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to load offers';
      setError(message);
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return { offers, loading, error, retryLoading };
}