import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

// Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);

// Enable offline persistence
enableIndexedDbPersistence(db, { synchronizeTabs: true })
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence enabled in first tab only');
    } else if (err.code === 'unimplemented') {
      console.warn('Browser doesn\'t support persistence');
    }
  });

// Unified error handling
export const handleFirebaseError = (error: any) => {
  console.error('Firebase error:', error);

  if (!navigator.onLine) {
    toast.error('You are offline. Please check your connection.');
    return;
  }

  // Handle specific Firebase errors
  switch (error.code) {
    // Auth errors
    case 'auth/invalid-email':
      toast.error('Invalid email address format');
      break;
    case 'auth/user-disabled':
      toast.error('This account has been disabled');
      break;
    case 'auth/user-not-found':
      toast.error('No account found with this email');
      break;
    case 'auth/wrong-password':
      toast.error('Incorrect password');
      break;
    case 'auth/email-already-in-use':
      toast.error('This email is already registered');
      break;
    case 'auth/operation-not-allowed':
      toast.error('Operation not allowed');
      break;
    case 'auth/weak-password':
      toast.error('Password must be at least 6 characters');
      break;
    case 'auth/invalid-argument':
      toast.error('Please check your input and try again');
      break;

    // Firestore errors
    case 'permission-denied':
      toast.error('Access denied. Please try signing in again.');
      auth.signOut();
      break;
    case 'unavailable':
      toast.error('Service temporarily unavailable. Please try again.');
      break;
    case 'not-found':
      toast.error('Required data not found. Please refresh.');
      break;
    case 'resource-exhausted':
      toast.error('Too many requests. Please try again later.');
      break;
    case 'failed-precondition':
      toast.error('Operation cannot be completed. Please try again.');
      break;
    case 'cancelled':
      // Don't show error for cancelled operations
      break;

    default:
      toast.error('An error occurred. Please try again.');
  }
};

// Helper function for retrying operations
export const retryOperation = async (
  operation: () => Promise<any>,
  maxAttempts = 3,
  baseDelay = 1000
): Promise<any> => {
  let lastError;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      return await operation();
    } catch (error: any) {
      lastError = error;
      
      // Don't retry these errors
      if (
        error.code === 'permission-denied' ||
        error.code === 'auth/user-disabled' ||
        error.code === 'auth/user-not-found'
      ) {
        throw error;
      }
      
      if (attempt === maxAttempts) {
        throw error;
      }
      
      // Exponential backoff with jitter
      const delay = Math.min(baseDelay * Math.pow(2, attempt - 1), 10000);
      const jitter = Math.random() * 200;
      await new Promise(resolve => setTimeout(resolve, delay + jitter));
    }
  }

  throw lastError;
};