import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, Gift, Lock, Star, Clock, Trophy, Flame, Crown, Sparkles } from 'lucide-react';
import { usePoints } from '../hooks/usePoints';
import { useDailyRewards } from '../hooks/useDailyRewards';
import { toast } from 'react-hot-toast';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';
import RewardPopup from './RewardPopup';

export default function DailyRewards() {
  const { rewards, claimReward, loading, currentDay, canClaim, streakInfo } = useDailyRewards();
  const [showRewardPopup, setShowRewardPopup] = useState(false);
  const [claimedReward, setClaimedReward] = useState<any>(null);

  const handleClaim = async (day: number) => {
    if (!canClaim) {
      toast.error('Come back tomorrow for your next reward!');
      return;
    }

    const result = await claimReward(day);
    if (result) {
      setClaimedReward(result);
      setShowRewardPopup(true);
    }
  };

  if (loading) {
    return (
      <GamingCard className="p-6 h-[520px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-cyan-500" />
      </GamingCard>
    );
  }

  return (
    <>
      <GamingCard className="p-6 h-[520px] flex flex-col">
        {/* Header with Streak Info */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-2">
            <Calendar className="h-5 w-5 text-cyan-400" />
            <h3 className="text-lg font-semibold text-white">Daily Rewards</h3>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Flame className="h-4 w-4 text-orange-400 animate-pulse" />
              <span className="text-sm text-orange-400">
                {streakInfo.current} Day Streak
              </span>
            </div>
            {streakInfo.multiplier > 1 && (
              <div className="flex items-center space-x-1 bg-cyan-500/20 px-3 py-1 rounded-full">
                <Sparkles className="h-4 w-4 text-cyan-400" />
                <span className="text-sm text-cyan-400">
                  {streakInfo.multiplier}x Multiplier
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Streak Progress */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-gray-400">Next Milestone</span>
            <span className="text-sm text-cyan-400">{streakInfo.nextMilestone} Days</span>
          </div>
          <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${(streakInfo.current / streakInfo.nextMilestone) * 100}%` }}
              transition={{ duration: 1, ease: "easeOut" }}
              className="h-full bg-gradient-to-r from-cyan-500 to-blue-500"
            />
          </div>
        </div>

        {/* Rewards Grid */}
        <div className="flex-1 overflow-y-auto pr-2 custom-scrollbar">
          <div className="space-y-3">
            {rewards.map((reward) => {
              const isAvailableToday = reward.available && !reward.claimed && canClaim;
              const isCurrent = reward.day === currentDay;
              
              return (
                <motion.div
                  key={reward.day}
                  whileHover={isAvailableToday ? { scale: 1.02 } : {}}
                  className={`relative p-4 rounded-lg border-2 transition-all duration-200 ${
                    reward.claimed
                      ? 'bg-green-500/10 border-green-500/20'
                      : isCurrent && canClaim
                      ? 'bg-cyan-500/10 border-cyan-500/20 cursor-pointer hover:border-cyan-500/40'
                      : reward.available
                      ? 'bg-gray-800/50 border-gray-700/50'
                      : 'bg-gray-800/30 border-gray-700/30 opacity-75'
                  }`}
                  onClick={() => isAvailableToday && handleClaim(reward.day)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <div className={`p-3 rounded-lg ${
                        reward.claimed
                          ? 'bg-green-500/20'
                          : isCurrent && canClaim
                          ? 'bg-cyan-500/20'
                          : reward.available
                          ? 'bg-gray-700/20'
                          : 'bg-gray-800/20'
                      }`}>
                        <span className="text-2xl">{reward.icon}</span>
                      </div>
                      <div>
                        <div className="flex items-center space-x-2">
                          <p className={`font-medium ${
                            reward.claimed
                              ? 'text-green-400'
                              : isCurrent && canClaim
                              ? 'text-cyan-400'
                              : reward.available
                              ? 'text-white'
                              : 'text-gray-400'
                          }`}>
                            Day {reward.day}
                          </p>
                          {isCurrent && canClaim && (
                            <span className="text-xs text-cyan-400 animate-pulse">
                              Claim Now!
                            </span>
                          )}
                        </div>
                        <p className="text-sm text-gray-400">{reward.bonus}</p>
                      </div>
                    </div>
                    <div className="flex flex-col items-end space-y-1">
                      <div className="flex items-center space-x-2">
                        <Star className={`h-4 w-4 ${
                          reward.claimed
                            ? 'text-green-400'
                            : isCurrent && canClaim
                            ? 'text-cyan-400'
                            : reward.available
                            ? 'text-yellow-400'
                            : 'text-gray-500'
                        }`} />
                        <span className={`font-medium ${
                          reward.claimed
                            ? 'text-green-400'
                            : isCurrent && canClaim
                            ? 'text-cyan-400'
                            : reward.available
                            ? 'text-yellow-400'
                            : 'text-gray-500'
                        }`}>
                          {reward.points.toLocaleString()}
                        </span>
                      </div>
                      {reward.claimed && (
                        <span className="text-xs text-green-400">Claimed</span>
                      )}
                      {streakInfo.multiplier > 1 && isCurrent && canClaim && (
                        <span className="text-xs text-cyan-400">
                          +{Math.floor(reward.points * (streakInfo.multiplier - 1))} bonus
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Progress bar for locked rewards */}
                  {!reward.available && (
                    <div className="mt-3">
                      <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                        <motion.div
                          initial={{ width: 0 }}
                          animate={{ width: `${reward.progress}%` }}
                          transition={{ duration: 1, ease: "easeOut" }}
                          className="h-full bg-gradient-to-r from-cyan-500 to-blue-500"
                        />
                      </div>
                      <div className="flex items-center justify-between mt-1">
                        <span className="text-xs text-gray-400">Progress</span>
                        <span className="text-xs text-gray-400">{Math.round(reward.progress)}%</span>
                      </div>
                    </div>
                  )}
                </motion.div>
              );
            })}
          </div>
        </div>

        {/* Footer */}
        {!canClaim && (
          <div className="mt-4 bg-gray-800/50 rounded-lg p-3 text-center">
            <p className="text-gray-400">
              Come back tomorrow for your next reward!
            </p>
          </div>
        )}
      </GamingCard>

      <RewardPopup
        isOpen={showRewardPopup}
        onClose={() => setShowRewardPopup(false)}
        title="Daily Reward Claimed!"
        points={claimedReward?.totalPoints || 0}
        streakDays={claimedReward?.streakDays}
        streakBonus={claimedReward?.streakBonus}
        milestoneBonus={claimedReward?.milestoneBonus}
        icon={claimedReward?.icon}
        message={`Keep your streak going for bigger rewards!`}
      />
    </>
  );
}