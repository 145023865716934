import { useEffect, useState } from 'react';

export function useDarkMode() {
  const [isDark, setIsDark] = useState(() => {
    const saved = localStorage.getItem('theme');
    // Default to dark theme if no preference is saved
    return saved ? saved === 'dark' : true;
  });

  useEffect(() => {
    const root = window.document.documentElement;
    
    if (isDark) {
      root.classList.add('dark');
      root.classList.remove('light');
      localStorage.setItem('theme', 'dark');
    } else {
      root.classList.remove('dark');
      root.classList.add('light');
      localStorage.setItem('theme', 'light');
    }

    // Set dark theme by default on first load
    if (!localStorage.getItem('theme')) {
      root.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
  }, [isDark]);

  return { isDark, setIsDark };
}