import { motion } from 'framer-motion';
import { Target, Gift, Star, ExternalLink, Clock } from 'lucide-react';
import { useUser } from '../hooks/useUser';
import { useOffers } from '../hooks/useOffers';
import { usePoints } from '../hooks/usePoints';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';
import { toast } from 'react-hot-toast';

export default function DailyTasks() {
  const { user } = useUser();
  const { offers } = useOffers();
  const { calculateMultiplier } = usePoints();
  const completedOffers = user?.completedOfferIds?.length || 0;
  const dailyTarget = 3;
  const progress = Math.min((completedOffers / dailyTarget) * 100, 100);
  const isCompleted = completedOffers >= dailyTarget;
  const multiplier = calculateMultiplier();

  // Get first 3 offers
  const featuredOffers = offers?.slice(0, 3) || [];

  const handleOfferClick = (url: string) => {
    window.open(url, '_blank');
    toast.success('Complete the offer to earn points!');
  };

  return (
    <GamingCard className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Target className="h-5 w-5 text-cyan-400" />
          <h3 className="text-lg font-semibold text-white">Daily Offer Challenge</h3>
        </div>
        <div className="flex items-center space-x-2">
          <Gift className="h-5 w-5 text-yellow-400" />
          <span className="text-yellow-400 font-medium">+20% Bonus</span>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-between text-sm">
          <span className="text-gray-400">Progress</span>
          <span className="text-cyan-400 font-medium">
            {completedOffers}/{dailyTarget} Offers
          </span>
        </div>

        {/* Progress Bar */}
        <div className="h-3 bg-gray-700 rounded-full overflow-hidden">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            className={`h-full rounded-full ${
              isCompleted
                ? 'bg-gradient-to-r from-green-500 to-emerald-500'
                : 'bg-gradient-to-r from-cyan-500 to-blue-500'
            }`}
          />
        </div>

        {/* Featured Offers */}
        <div className="space-y-3 mt-6">
          <h4 className="text-sm font-medium text-gray-400">Featured Offers</h4>
          {featuredOffers.map((offer, index) => {
            const basePoints = offer.points;
            const finalPoints = Math.floor(basePoints * multiplier);

            return (
              <motion.div
                key={offer.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-800/50 rounded-lg p-4"
              >
                <div className="flex items-center justify-between">
                  <div className="flex-1">
                    <h5 className="text-white font-medium mb-1 line-clamp-1">
                      {offer.title}
                    </h5>
                    <div className="flex items-center space-x-3 text-sm">
                      <div className="flex items-center text-gray-400">
                        <Clock className="h-4 w-4 mr-1" />
                        <span>~{offer.estimatedTime} min</span>
                      </div>
                      <div className="flex items-center text-cyan-400">
                        <Star className="h-4 w-4 mr-1" />
                        <span>{finalPoints.toLocaleString()} pts</span>
                      </div>
                    </div>
                  </div>
                  <GamingButton
                    variant="primary"
                    size="sm"
                    onClick={() => handleOfferClick(offer.url)}
                  >
                    Start
                    <ExternalLink className="ml-2 h-4 w-4" />
                  </GamingButton>
                </div>
              </motion.div>
            );
          })}
        </div>

        {/* Status Message */}
        <div className={`text-sm ${isCompleted ? 'text-green-400' : 'text-gray-400'}`}>
          {isCompleted ? (
            <div className="flex items-center space-x-2">
              <Star className="h-4 w-4" />
              <span>Challenge completed! +20% bonus activated</span>
            </div>
          ) : (
            `Complete ${dailyTarget - completedOffers} more offers to earn a 20% bonus on all earnings today!`
          )}
        </div>
      </div>
    </GamingCard>
  );
}