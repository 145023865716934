import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

export async function updateLastActive(userId: string) {
  if (!userId) return;
  
  try {
    const userRef = doc(db, 'users', userId);
    
    // First check if user document exists
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      console.warn('User document not found for activity update');
      return;
    }

    // Only update if user is not banned
    const userData = userDoc.data();
    if (userData.isBanned) {
      console.warn('User is banned, skipping activity update');
      return;
    }

    await updateDoc(userRef, {
      lastActiveAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating last active time:', error);
    // Don't throw error to prevent app crashes
  }
}