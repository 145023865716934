import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth, db } from '../lib/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, AuthError } from 'firebase/auth';
import { doc, setDoc, collection } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import { Lock, Loader2, Gift } from 'lucide-react';
import PageTransition from '../components/PageTransition';
import GamingCard from '../components/GamingCard';
import AnimatedBackground from '../components/AnimatedBackground';
import LoginForm from '../components/auth/LoginForm';
import SignupForm from '../components/auth/SignupForm';
import { ADMIN_EMAILS } from '../lib/constants';

export default function Login() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Check if signup is requested via URL param
    const signupParam = searchParams.get('signup');
    if (signupParam === 'true') {
      setIsSignUp(true);
    }

    // Check if user is already logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Redirect to appropriate page
        if (ADMIN_EMAILS.includes(user.email || '')) {
          navigate('/admin', { replace: true });
        } else {
          navigate('/dashboard', { replace: true });
        }
      }
    });

    return () => unsubscribe();
  }, [navigate, searchParams]);

  const handleSignUp = async (data: any) => {
    if (loading) return;

    try {
      setLoading(true);

      // Create Firebase auth user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email.trim(),
        data.password
      );
      const user = userCredential.user;

      // Generate unique referral code
      const newReferralCode = Math.random().toString(36).substring(2, 8).toUpperCase();

      // Create user document
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        uid: user.uid,
        email: user.email,
        displayName: data.name.trim(),
        country: data.country,
        createdAt: new Date().toISOString(),
        lastLoginDate: new Date().toISOString(),
        lastActiveAt: new Date().toISOString(),
        points: 500,
        totalEarned: 500,
        streakDays: 1,
        completedOffers: 0,
        completedOfferIds: [],
        referralCode: newReferralCode,
        referrals: [],
        hasSeenWelcome: false,
        bonusPoints: {
          daily: 0,
          games: 0,
          referral: 0,
          signup: 500
        }
      });

      // Store referral code
      const codeRef = doc(collection(db, 'referralCodes'), newReferralCode);
      await setDoc(codeRef, {
        code: newReferralCode,
        userId: user.uid,
        createdAt: new Date().toISOString()
      });

      // Play success sound
      const audio = new Audio('/sounds/bonus.mp3');
      audio.play().catch(() => {});

      toast.success('Account created successfully!');
      
      // Redirect will be handled by the auth state listener
    } catch (error) {
      handleAuthError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (data: any) => {
    if (loading) return;

    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, data.email.trim(), data.password);
      toast.success('Logged in successfully!');
      // Redirect will be handled by the auth state listener
    } catch (error) {
      handleAuthError(error as AuthError);
    } finally {
      setLoading(false);
    }
  };

  const handleAuthError = (error: AuthError) => {
    switch (error.code) {
      case 'auth/email-already-in-use':
        toast.error('This email is already registered');
        break;
      case 'auth/invalid-email':
        toast.error('Invalid email address');
        break;
      case 'auth/weak-password':
        toast.error('Password must be at least 6 characters');
        break;
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        toast.error('Invalid email or password');
        break;
      default:
        toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <PageTransition>
      <div className="min-h-screen relative overflow-hidden bg-gray-900">
        <AnimatedBackground />
        
        <div className="relative z-10 flex items-center justify-center min-h-screen py-12 px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full max-w-md"
          >
            <GamingCard className="p-8">
              {/* Logo */}
              <div className="text-center mb-8">
                <motion.div 
                  className="mx-auto h-12 w-12 flex items-center justify-center rounded-full bg-cyan-500/20"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Lock className="h-6 w-6 text-cyan-400" />
                </motion.div>
                <h2 className="mt-6 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-400">
                  {isSignUp ? 'Create Account' : 'Welcome Back'}
                </h2>
                {isSignUp && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="mt-4"
                  >
                    <div className="flex items-center justify-center space-x-2">
                      <Gift className="h-5 w-5 text-cyan-400" />
                      <span className="text-cyan-400">Get 500 points on signup!</span>
                    </div>
                  </motion.div>
                )}
              </div>

              {/* Forms */}
              <AnimatePresence mode="wait">
                {isSignUp ? (
                  <SignupForm onSubmit={handleSignUp} loading={loading} />
                ) : (
                  <LoginForm onSubmit={handleLogin} loading={loading} />
                )}
              </AnimatePresence>

              {/* Toggle Sign Up/Sign In */}
              <div className="mt-6 text-center">
                <button
                  type="button"
                  onClick={() => setIsSignUp(!isSignUp)}
                  className="text-cyan-400 hover:text-cyan-300 transition-colors"
                >
                  {isSignUp
                    ? 'Already have an account? Sign in'
                    : "Don't have an account? Sign up"}
                </button>
              </div>
            </GamingCard>
          </motion.div>
        </div>
      </div>
    </PageTransition>
  );
}