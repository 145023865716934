import { useState, useCallback } from 'react';
import { useUser } from './useUser';
import { 
  calculateMultiplier as calcMultiplier,
  calculateLevel,
  calculateLevelProgress,
  addPoints as addUserPoints,
  deductPoints as deductUserPoints
} from '../lib/points';
import { handleFirebaseError } from '../lib/firebase';

export function usePoints() {
  const { user } = useUser();
  const [isProcessing, setIsProcessing] = useState(false);
  const [animatingPoints, setAnimatingPoints] = useState<number | null>(null);

  const calculateMultiplier = useCallback(() => {
    if (!user) return 1;
    
    const level = calculateLevel(user.points);
    return calcMultiplier(
      user.streakDays || 0,
      user.achievements?.length || 0,
      level
    );
  }, [user]);

  const animatePoints = (startPoints: number, endPoints: number, duration: number = 1000) => {
    const startTime = Date.now();
    const difference = endPoints - startPoints;

    const animate = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      const easeOutQuart = 1 - Math.pow(1 - progress, 4);
      const currentValue = Math.floor(startPoints + difference * easeOutQuart);

      setAnimatingPoints(currentValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setAnimatingPoints(null);
      }
    };

    animate();
  };

  const addPoints = async (
    amount: number,
    type: 'offer' | 'daily' | 'achievement' | 'referral' | 'game' | 'bonus',
    description: string
  ) => {
    if (!user || isProcessing) return 0;

    try {
      setIsProcessing(true);
      const multiplier = calculateMultiplier();
      const success = await addUserPoints(user.uid, amount, type, description, multiplier);

      if (success) {
        // Animate points change
        const currentPoints = user.points || 0;
        const finalAmount = Math.floor(amount * multiplier);
        animatePoints(currentPoints, currentPoints + finalAmount);
        return finalAmount;
      }

      return 0;
    } catch (error) {
      handleFirebaseError(error);
      return 0;
    } finally {
      setIsProcessing(false);
    }
  };

  const deductPoints = async (
    amount: number,
    type: 'withdrawal' | 'purchase',
    description: string
  ) => {
    if (!user || isProcessing) return false;

    try {
      setIsProcessing(true);
      const success = await deductUserPoints(user.uid, amount, type, description);

      if (success) {
        // Animate points change
        const currentPoints = user.points || 0;
        animatePoints(currentPoints, currentPoints - amount);
      }

      return success;
    } catch (error) {
      handleFirebaseError(error);
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    addPoints,
    deductPoints,
    calculateMultiplier,
    isProcessing,
    animatingPoints,
    level: user ? calculateLevel(user.points) : 1,
    levelProgress: user ? calculateLevelProgress(user.points) : 0
  };
}