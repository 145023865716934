import { motion } from 'framer-motion';
import { Quote, Star } from 'lucide-react';
import GamingCard from './GamingCard';

const TESTIMONIALS = [
  {
    id: 1,
    content: "I was skeptical at first, but I've already earned over $500 in my first month! The platform is super easy to use and payouts are always on time.",
    author: "Michael Chen",
    role: "Student",
    avatar: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100",
    rating: 5,
    earned: "$500+"
  },
  {
    id: 2,
    content: "The best rewards platform I've used. Daily bonuses and streak rewards really add up. Customer support is always helpful when needed.",
    author: "Emma Wilson",
    role: "Freelancer",
    avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100",
    rating: 5,
    earned: "$300+"
  },
  {
    id: 3,
    content: "What I love most is the variety of earning options. Whether it's surveys, offers, or watching videos, there's always something to do.",
    author: "David Martinez",
    role: "Professional",
    avatar: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?w=100",
    rating: 5,
    earned: "$750+"
  }
];

export default function Testimonials() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {TESTIMONIALS.map((testimonial, index) => (
        <motion.div
          key={testimonial.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.2 }}
          whileHover={{ scale: 1.02 }}
        >
          <GamingCard className="p-6 h-full flex flex-col">
            {/* Quote Icon */}
            <div className="mb-4">
              <Quote className="h-8 w-8 text-cyan-400" />
            </div>

            {/* Content */}
            <p className="text-gray-300 flex-grow mb-6">
              "{testimonial.content}"
            </p>

            {/* Rating */}
            <div className="flex items-center mb-4">
              {Array.from({ length: testimonial.rating }).map((_, i) => (
                <Star key={i} className="h-4 w-4 text-yellow-400 fill-yellow-400" />
              ))}
            </div>

            {/* Author Info */}
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <img
                  src={testimonial.avatar}
                  alt={testimonial.author}
                  className="w-10 h-10 rounded-full border-2 border-cyan-500/20"
                />
                <div>
                  <p className="font-semibold text-white">{testimonial.author}</p>
                  <p className="text-sm text-gray-400">{testimonial.role}</p>
                </div>
              </div>
              <div className="bg-green-500/20 px-3 py-1 rounded-full">
                <span className="text-sm text-green-400">{testimonial.earned}</span>
              </div>
            </div>

            {/* Animated Border */}
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: '100%' }}
              transition={{ duration: 1, delay: index * 0.3 }}
              className="absolute bottom-0 left-0 h-1 bg-gradient-to-r from-cyan-500 to-purple-500 rounded-full"
            />
          </GamingCard>
        </motion.div>
      ))}
    </div>
  );
}