import { useState } from 'react';
import { motion } from 'framer-motion';
import { Calculator, DollarSign, Clock, Target, Star } from 'lucide-react';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';

export default function EarningsCalculator() {
  const [offersPerDay, setOffersPerDay] = useState(5);
  const [daysPerWeek, setDaysPerWeek] = useState(5);
  const [streakBonus, setStreakBonus] = useState(true);
  const [referralBonus, setReferralBonus] = useState(true);

  const calculateEarnings = () => {
    const avgPointsPerOffer = 500;
    const basePoints = offersPerDay * avgPointsPerOffer;
    const weeklyBase = basePoints * daysPerWeek;
    const monthlyBase = weeklyBase * 4;

    let multiplier = 1;
    if (streakBonus) multiplier += 0.5; // 50% streak bonus
    if (referralBonus) multiplier += 0.3; // 30% referral bonus

    const weeklyTotal = Math.floor(weeklyBase * multiplier);
    const monthlyTotal = Math.floor(monthlyBase * multiplier);
    const monthlyUSD = Math.floor(monthlyTotal / 1000); // 1000 points = $1

    return {
      daily: Math.floor(basePoints * multiplier),
      weekly: weeklyTotal,
      monthly: monthlyTotal,
      monthlyUSD
    };
  };

  const earnings = calculateEarnings();

  return (
    <GamingCard className="p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <Calculator className="h-6 w-6 text-cyan-400" />
          <h3 className="text-xl font-bold text-white">Earnings Calculator</h3>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Controls */}
        <div className="space-y-6">
          <div>
            <label className="block text-sm text-gray-400 mb-2">
              Offers Per Day
            </label>
            <div className="flex items-center space-x-4">
              <input
                type="range"
                min="1"
                max="10"
                value={offersPerDay}
                onChange={(e) => setOffersPerDay(Number(e.target.value))}
                className="flex-grow h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-cyan-500"
              />
              <span className="text-white font-bold w-8">{offersPerDay}</span>
            </div>
          </div>

          <div>
            <label className="block text-sm text-gray-400 mb-2">
              Days Per Week
            </label>
            <div className="flex items-center space-x-4">
              <input
                type="range"
                min="1"
                max="7"
                value={daysPerWeek}
                onChange={(e) => setDaysPerWeek(Number(e.target.value))}
                className="flex-grow h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-cyan-500"
              />
              <span className="text-white font-bold w-8">{daysPerWeek}</span>
            </div>
          </div>

          <div className="space-y-3">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={streakBonus}
                onChange={(e) => setStreakBonus(e.target.checked)}
                className="w-4 h-4 rounded text-cyan-500 focus:ring-cyan-500 bg-gray-700 border-gray-600"
              />
              <span className="text-gray-300">Include Streak Bonus (50%)</span>
            </label>

            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={referralBonus}
                onChange={(e) => setReferralBonus(e.target.checked)}
                className="w-4 h-4 rounded text-cyan-500 focus:ring-cyan-500 bg-gray-700 border-gray-600"
              />
              <span className="text-gray-300">Include Referral Bonus (30%)</span>
            </label>
          </div>
        </div>

        {/* Results */}
        <div className="space-y-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="grid grid-cols-2 gap-4"
          >
            <div className="bg-gray-800/50 rounded-lg p-4">
              <div className="flex items-center space-x-2 mb-2">
                <Target className="h-4 w-4 text-cyan-400" />
                <p className="text-sm text-gray-400">Daily Points</p>
              </div>
              <p className="text-2xl font-bold text-white">
                {earnings.daily.toLocaleString()}
              </p>
            </div>

            <div className="bg-gray-800/50 rounded-lg p-4">
              <div className="flex items-center space-x-2 mb-2">
                <Clock className="h-4 w-4 text-cyan-400" />
                <p className="text-sm text-gray-400">Weekly Points</p>
              </div>
              <p className="text-2xl font-bold text-white">
                {earnings.weekly.toLocaleString()}
              </p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-gradient-to-br from-cyan-500/20 to-blue-500/20 rounded-lg p-6"
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-2">
                <Star className="h-5 w-5 text-cyan-400" />
                <p className="text-lg font-bold text-white">Monthly Earnings</p>
              </div>
              <DollarSign className="h-5 w-5 text-cyan-400" />
            </div>
            <div className="space-y-2">
              <p className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 to-blue-400">
                {earnings.monthly.toLocaleString()} pts
              </p>
              <p className="text-xl text-cyan-400">
                ≈ ${earnings.monthlyUSD.toLocaleString()}
              </p>
            </div>
          </motion.div>

          <div className="text-sm text-gray-400">
            * Estimated earnings based on average offer values. Actual earnings may vary.
          </div>
        </div>
      </div>
    </GamingCard>
  );
}