import { motion } from 'framer-motion';
import { ReactNode } from 'react';

interface GamingCardProps {
  children: ReactNode;
  className?: string;
  variant?: 'default' | 'achievement' | 'reward';
}

export default function GamingCard({
  children,
  className = '',
  variant = 'default'
}: GamingCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.02, y: -5 }}
      className={`
        relative overflow-hidden
        bg-gradient-to-br from-gray-900 to-gray-800
        rounded-xl border border-cyan-500/20
        shadow-[0_0_15px_rgba(0,242,254,0.1)]
        ${className}
      `}
    >
      {/* Animated border */}
      <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/20 via-purple-500/20 to-cyan-500/20 animate-border-flow" />
      
      {/* Glow effect on hover */}
      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/10 to-purple-500/10 animate-pulse" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        {children}
      </div>

      {/* Corner accents */}
      <div className="absolute top-0 left-0 w-4 h-4 border-t-2 border-l-2 border-cyan-500/30" />
      <div className="absolute top-0 right-0 w-4 h-4 border-t-2 border-r-2 border-cyan-500/30" />
      <div className="absolute bottom-0 left-0 w-4 h-4 border-b-2 border-l-2 border-cyan-500/30" />
      <div className="absolute bottom-0 right-0 w-4 h-4 border-b-2 border-r-2 border-cyan-500/30" />
    </motion.div>
  );
}