import { Moon, Sun } from 'lucide-react';
import { motion } from 'framer-motion';
import { useDarkMode } from '../hooks/useDarkMode';

export default function ThemeToggle() {
  const { isDark, setIsDark } = useDarkMode();

  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => setIsDark(!isDark)}
      className={`p-2 rounded-lg transition-all duration-200 ${
        isDark 
          ? 'bg-gray-800 border border-cyan-500/20 hover:border-cyan-500/40' 
          : 'bg-white border border-gray-200 hover:border-gray-300 shadow-sm'
      }`}
      aria-label="Toggle dark mode"
    >
      {isDark ? (
        <Moon className="h-5 w-5 text-cyan-400" />
      ) : (
        <Sun className="h-5 w-5 text-orange-400" />
      )}
    </motion.button>
  );
}