import { useEffect, useCallback, useRef } from 'react';
import { checkLeads } from '../lib/api';
import { useAuth } from './useAuth';
import { doc, updateDoc, increment, collection, addDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import { usePoints } from './usePoints';

export function useLeadTracking() {
  const { user } = useAuth();
  const { calculateMultiplier } = usePoints();
  const processedLeads = useRef<Set<string>>(new Set());
  const lastCheckTimestamp = useRef<number>(Date.now());

  const updateUserPoints = useCallback(async (dollarValue: number, offerId: string) => {
    if (!user) return;
    
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }

      // Check if this offer was already processed
      const userData = userDoc.data();
      const completedOffers = userData.completedOfferIds || [];
      if (completedOffers.includes(offerId)) {
        console.log('Offer already completed:', offerId);
        return 0;
      }

      // Calculate base points (dollar value × 1000)
      const basePoints = Math.floor(dollarValue * 1000);
      const multiplier = calculateMultiplier();
      const finalPoints = Math.floor(basePoints * multiplier);
      
      // Update user points and stats
      await updateDoc(userRef, {
        points: increment(finalPoints),
        completedOffers: increment(1),
        completedOfferIds: [...completedOffers, offerId],
        totalEarned: increment(finalPoints),
        'bonusPoints.offer': increment(finalPoints),
        lastCompletedOffer: new Date().toISOString()
      });

      // Record transaction
      await addDoc(collection(db, `users/${user.uid}/transactions`), {
        amount: finalPoints,
        baseAmount: basePoints,
        multiplier,
        type: 'offer',
        offerId,
        timestamp: new Date().toISOString(),
        description: `Completed offer #${offerId} ($${dollarValue.toFixed(2)})`
      });

      // Play success sound
      const audio = new Audio('/sounds/coin.mp3');
      audio.play().catch(() => {}); // Ignore if sound fails to play

      // Show success notification
      toast.success(
        multiplier > 1
          ? `Offer completed! +${finalPoints.toLocaleString()} points (${basePoints.toLocaleString()} × ${multiplier.toFixed(1)} multiplier)`
          : `Offer completed! +${finalPoints.toLocaleString()} points`,
        { duration: 5000 }
      );

      return finalPoints;
    } catch (error) {
      console.error('Error updating user points:', error);
      toast.error('Failed to update points');
      return 0;
    }
  }, [user, calculateMultiplier]);

  useEffect(() => {
    if (!user) return;

    let isActive = true;
    const checkInterval = setInterval(async () => {
      if (!isActive) return;

      try {
        const currentTime = Date.now();
        // Only check for new leads every 30 seconds
        if (currentTime - lastCheckTimestamp.current < 30000) {
          return;
        }
        lastCheckTimestamp.current = currentTime;

        const leads = await checkLeads();
        
        if (Array.isArray(leads) && leads.length > 0) {
          for (const lead of leads) {
            const leadId = `${lead.offer_id}-${lead.points}`;
            if (!processedLeads.current.has(leadId)) {
              processedLeads.current.add(leadId);
              const dollarValue = parseFloat(lead.points);
              if (!isNaN(dollarValue) && dollarValue > 0) {
                await updateUserPoints(dollarValue, lead.offer_id);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error checking leads:', error);
      }
    }, 15000); // Check every 15 seconds

    return () => {
      isActive = false;
      clearInterval(checkInterval);
    };
  }, [user, updateUserPoints]);

  return { updateUserPoints };
}