import { useState } from 'react';
import { motion } from 'framer-motion';
import { User, Mail, Lock, Camera, Save, AlertCircle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useUser } from '../hooks/useUser';
import { auth, db } from '../lib/firebase';
import { updateProfile, updateEmail, updatePassword } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import PageTransition from '../components/PageTransition';
import GamingHeading from '../components/GamingHeading';
import GamingButton from '../components/GamingButton';
import GamingCard from '../components/GamingCard';

const DEFAULT_AVATAR = 'https://api.dicebear.com/7.x/pixel-art/svg?seed=';

export default function Profile() {
  const { user: authUser } = useAuth();
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    displayName: user?.displayName || '',
    email: user?.email || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    avatar: user?.photoURL || `${DEFAULT_AVATAR}${user?.uid}`
  });

  const handleAvatarChange = () => {
    // Generate a new random avatar
    const newSeed = Math.random().toString(36).substring(7);
    setFormData(prev => ({
      ...prev,
      avatar: `${DEFAULT_AVATAR}${newSeed}`
    }));
  };

  const updateUserProfile = async () => {
    if (!authUser) return;

    try {
      setLoading(true);

      // Update display name and avatar
      await updateProfile(authUser, {
        displayName: formData.displayName,
        photoURL: formData.avatar
      });

      // Update Firestore document
      const userRef = doc(db, 'users', authUser.uid);
      await updateDoc(userRef, {
        displayName: formData.displayName,
        photoURL: formData.avatar
      });

      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const updateUserEmail = async () => {
    if (!authUser) return;

    try {
      setLoading(true);
      await updateEmail(authUser, formData.email);
      
      const userRef = doc(db, 'users', authUser.uid);
      await updateDoc(userRef, {
        email: formData.email
      });

      toast.success('Email updated successfully!');
    } catch (error) {
      console.error('Error updating email:', error);
      toast.error('Failed to update email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const updateUserPassword = async () => {
    if (!authUser) return;

    if (formData.newPassword !== formData.confirmPassword) {
      toast.error('New passwords do not match');
      return;
    }

    try {
      setLoading(true);
      await updatePassword(authUser, formData.newPassword);
      toast.success('Password updated successfully!');
      
      // Clear password fields
      setFormData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
    } catch (error) {
      console.error('Error updating password:', error);
      toast.error('Failed to update password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageTransition>
      <div className="min-h-screen bg-gray-900 py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <GamingHeading level={1} variant="gradient" className="text-4xl mb-8 text-center">
            Profile Settings
          </GamingHeading>

          <div className="space-y-8">
            {/* Avatar Section */}
            <GamingCard className="p-6">
              <div className="flex flex-col items-center space-y-4">
                <div className="relative group">
                  <img
                    src={formData.avatar}
                    alt="Profile Avatar"
                    className="w-32 h-32 rounded-full border-4 border-cyan-500/20"
                  />
                  <button
                    onClick={handleAvatarChange}
                    className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <Camera className="h-8 w-8 text-white" />
                  </button>
                </div>
                <p className="text-gray-400 text-sm">Click to generate a new avatar</p>
              </div>
            </GamingCard>

            {/* Profile Information */}
            <GamingCard className="p-6 space-y-6">
              <h2 className="text-xl font-cyber text-cyan-400 flex items-center">
                <User className="h-5 w-5 mr-2" />
                Basic Information
              </h2>

              <div className="space-y-4">
                <div>
                  <label className="block text-white mb-2">Display Name</label>
                  <input
                    type="text"
                    value={formData.displayName}
                    onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  />
                </div>

                <GamingButton
                  onClick={updateUserProfile}
                  disabled={loading}
                  className="w-full"
                >
                  <Save className="h-5 w-5 mr-2" />
                  Update Profile
                </GamingButton>
              </div>
            </GamingCard>

            {/* Email Settings */}
            <GamingCard className="p-6 space-y-6">
              <h2 className="text-xl font-cyber text-cyan-400 flex items-center">
                <Mail className="h-5 w-5 mr-2" />
                Email Settings
              </h2>

              <div className="space-y-4">
                <div>
                  <label className="block text-white mb-2">Email Address</label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  />
                </div>

                <GamingButton
                  onClick={updateUserEmail}
                  disabled={loading}
                  className="w-full"
                >
                  <Save className="h-5 w-5 mr-2" />
                  Update Email
                </GamingButton>
              </div>
            </GamingCard>

            {/* Password Settings */}
            <GamingCard className="p-6 space-y-6">
              <h2 className="text-xl font-cyber text-cyan-400 flex items-center">
                <Lock className="h-5 w-5 mr-2" />
                Password Settings
              </h2>

              <div className="space-y-4">
                <div>
                  <label className="block text-white mb-2">Current Password</label>
                  <input
                    type="password"
                    value={formData.currentPassword}
                    onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  />
                </div>

                <div>
                  <label className="block text-white mb-2">New Password</label>
                  <input
                    type="password"
                    value={formData.newPassword}
                    onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  />
                </div>

                <div>
                  <label className="block text-white mb-2">Confirm New Password</label>
                  <input
                    type="password"
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                    className="w-full px-4 py-2 bg-gray-800 border border-cyan-500/20 rounded-lg focus:border-cyan-500 focus:ring-1 focus:ring-cyan-500 text-white"
                  />
                </div>

                <GamingButton
                  onClick={updateUserPassword}
                  disabled={loading}
                  className="w-full"
                >
                  <Save className="h-5 w-5 mr-2" />
                  Update Password
                </GamingButton>
              </div>
            </GamingCard>

            {/* Security Notice */}
            <div className="flex items-start space-x-4 p-4 bg-yellow-500/10 rounded-lg border border-yellow-500/20">
              <AlertCircle className="h-6 w-6 text-yellow-500 flex-shrink-0 mt-0.5" />
              <div className="text-sm text-yellow-200">
                <p className="font-medium mb-1">Security Notice</p>
                <p className="text-yellow-200/80">
                  For your security, you'll be logged out after changing your email or password. 
                  Please log in again with your updated credentials.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  );
}