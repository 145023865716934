import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Gift, Target, Trophy, Users, Star, DollarSign, ChevronRight, ChevronLeft, X } from 'lucide-react';
import GamingButton from './GamingButton';

const STEPS = [
  {
    title: 'Welcome to GainJourney! 🎉',
    description: 'Your journey to earning rewards starts here. Let us show you how to get started.',
    Icon: Gift,
    color: 'from-cyan-500 to-blue-500'
  },
  {
    title: 'Complete Offers 💰',
    description: "Earn points by completing surveys, watching videos, and trying new apps. Each offer clearly shows how many points you'll earn.",
    Icon: Target,
    color: 'from-green-500 to-emerald-500'
  },
  {
    title: 'Daily Rewards & Streaks 🔥',
    description: 'Log in daily to claim bonus points. Maintain your streak for multipliers up to 2x on all earnings!',
    Icon: Star,
    color: 'from-yellow-500 to-amber-500'
  },
  {
    title: 'Play Games 🎮',
    description: 'Try our Crash game and Lucky Spin for a chance to multiply your points instantly!',
    Icon: Trophy,
    color: 'from-purple-500 to-pink-500'
  },
  {
    title: 'Refer Friends 👥',
    description: "Share your referral code and earn 30% of your friends' earnings forever! They get a 1000 point bonus too.",
    Icon: Users,
    color: 'from-indigo-500 to-violet-500'
  },
  {
    title: 'Cash Out 💸',
    description: 'Convert your points to PayPal cash, gift cards, or cryptocurrency. 1000 points = $1. Minimum withdrawal is $15.',
    Icon: DollarSign,
    color: 'from-red-500 to-orange-500'
  }
];

interface WelcomePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function WelcomePopup({ isOpen, onClose }: WelcomePopupProps) {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep < STEPS.length - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      onClose();
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const CurrentIcon = STEPS[currentStep].Icon;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="relative max-w-lg w-full mx-4 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl border border-cyan-500/20 shadow-2xl overflow-hidden"
          >
            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
            >
              <X className="h-5 w-5" />
            </button>

            {/* Progress bar */}
            <div className="absolute top-0 left-0 w-full h-1 bg-gray-800">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${((currentStep + 1) / STEPS.length) * 100}%` }}
                className="h-full bg-gradient-to-r from-cyan-500 to-blue-500"
              />
            </div>

            {/* Content */}
            <div className="p-8 pt-12">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentStep}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="text-center"
                >
                  {/* Icon */}
                  <motion.div
                    animate={{
                      scale: [1, 1.2, 1],
                      rotate: [0, 360],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                    className="mx-auto mb-6"
                  >
                    <div className={`inline-block p-4 rounded-xl bg-gradient-to-br ${STEPS[currentStep].color}`}>
                      <CurrentIcon className="h-8 w-8 text-white" />
                    </div>
                  </motion.div>

                  {/* Title */}
                  <h2 className="text-2xl font-bold text-white mb-4">
                    {STEPS[currentStep].title}
                  </h2>

                  {/* Description */}
                  <p className="text-gray-300 mb-8">
                    {STEPS[currentStep].description}
                  </p>

                  {/* Navigation */}
                  <div className="flex items-center justify-between">
                    <GamingButton
                      variant="secondary"
                      onClick={prevStep}
                      disabled={currentStep === 0}
                    >
                      <ChevronLeft className="h-5 w-5 mr-2" />
                      Previous
                    </GamingButton>

                    <div className="flex items-center space-x-2">
                      {STEPS.map((_, index) => (
                        <div
                          key={index}
                          className={`w-2 h-2 rounded-full ${
                            index === currentStep
                              ? 'bg-cyan-500'
                              : 'bg-gray-600'
                          }`}
                        />
                      ))}
                    </div>

                    <GamingButton
                      variant="primary"
                      onClick={nextStep}
                      glowIntensity="high"
                    >
                      {currentStep === STEPS.length - 1 ? (
                        'Get Started'
                      ) : (
                        <>
                          Next
                          <ChevronRight className="h-5 w-5 ml-2" />
                        </>
                      )}
                    </GamingButton>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}