import { useState } from 'react';
import { motion } from 'framer-motion';
import { Zap, Star, RefreshCw, DollarSign } from 'lucide-react';
import { useUser } from '../hooks/useUser';
import { usePoints } from '../hooks/usePoints';
import { doc, getDoc } from 'firebase/firestore';
import { db, retryOperation } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import GamingCard from './GamingCard';
import GamingButton from './GamingButton';

const POINTS_TO_USD = 1000; // 1000 points = $1

interface PointsDisplayProps {
  refs: {
    dailyRewards: React.RefObject<HTMLDivElement>;
    luckySpin: React.RefObject<HTMLDivElement>;
    watchAds: React.RefObject<HTMLDivElement>;
  };
}

export default function PointsDisplay({ refs }: PointsDisplayProps) {
  const { user } = useUser();
  const { calculateMultiplier, animatingPoints } = usePoints();
  const [refreshing, setRefreshing] = useState(false);
  const [showUSD, setShowUSD] = useState(false);
  const multiplier = user ? calculateMultiplier() : 1;

  if (!user) return null;

  const displayPoints = animatingPoints !== null ? animatingPoints : user.points;
  const usdAmount = (displayPoints / POINTS_TO_USD).toFixed(2);

  const refreshBalance = async () => {
    if (refreshing) return;
    
    try {
      setRefreshing(true);
      await retryOperation(async () => {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          toast.success('Balance refreshed!');
        }
      });
    } catch (error) {
      toast.error('Failed to refresh balance');
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <GamingCard className="p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-gray-400 text-sm">Current Balance</p>
          <div className="flex items-center space-x-3">
            <motion.div
              key={displayPoints}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="flex items-center"
            >
              <p className="text-4xl font-bold text-white mt-1">
                {showUSD ? `$${usdAmount}` : `${displayPoints.toLocaleString()}`}
                <span className="text-lg text-gray-400 ml-1">{showUSD ? 'USD' : 'pts'}</span>
              </p>
              <button
                onClick={() => setShowUSD(!showUSD)}
                className="ml-2 p-1 rounded-full hover:bg-gray-700/50 transition-colors"
              >
                {showUSD ? <Star className="h-5 w-5 text-yellow-400" /> : <DollarSign className="h-5 w-5 text-green-400" />}
              </button>
            </motion.div>
            <GamingButton
              variant="secondary"
              size="sm"
              onClick={refreshBalance}
              disabled={refreshing}
              className={refreshing ? 'animate-spin' : 'hover:animate-pulse'}
            >
              <RefreshCw className="h-4 w-4" />
            </GamingButton>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <div className="flex items-center space-x-1 bg-cyan-500/20 px-3 py-1.5 rounded-full">
            <Zap className="h-4 w-4 text-cyan-400" />
            <span className="text-sm font-medium text-cyan-400">
              {multiplier.toFixed(1)}x Multiplier
            </span>
          </div>
          {user.streakDays > 0 && (
            <p className="text-sm text-gray-400 mt-2">
              🔥 {user.streakDays} Day Streak
            </p>
          )}
        </div>
      </div>
    </GamingCard>
  );
}