import { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useUser } from './useUser';
import { toast } from 'react-hot-toast';
import type { DailyReward } from '../types';

// Enhanced rewards with better progression
const DAILY_REWARDS = [
  { day: 1, points: 200, bonus: 'Welcome Gift', icon: '🎁' },
  { day: 2, points: 300, bonus: 'Early Bird', icon: '🌅' },
  { day: 3, points: 500, bonus: 'Consistency', icon: '🎯' },
  { day: 4, points: 750, bonus: 'Dedication', icon: '⭐' },
  { day: 5, points: 1000, bonus: 'Commitment', icon: '🏆' },
  { day: 6, points: 1500, bonus: 'Excellence', icon: '💫' },
  { day: 7, points: 2500, bonus: 'Weekly Champion', icon: '👑' }
];

// Streak bonuses
const STREAK_MULTIPLIERS = {
  WEEK: 1.5,    // 50% bonus for week streak
  MONTH: 2.0,   // 100% bonus for month streak
  QUARTER: 2.5  // 150% bonus for quarter streak
};

export function useDailyRewards() {
  const { user } = useUser();
  const [rewards, setRewards] = useState<(DailyReward & { bonus: string; icon: string })[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentDay, setCurrentDay] = useState(0);
  const [canClaim, setCanClaim] = useState(false);
  const [streakInfo, setStreakInfo] = useState({
    current: 0,
    multiplier: 1,
    nextMilestone: 7
  });

  useEffect(() => {
    if (!user) return;

    const calculateDaysSinceRegistration = () => {
      const signupTransaction = user.transactions?.find(t => t.type === 'signup');
      if (!signupTransaction) return 1;
      
      const registrationDate = new Date(signupTransaction.timestamp);
      const today = new Date();
      
      registrationDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      
      const diffTime = Math.abs(today.getTime() - registrationDate.getTime());
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      return ((diffDays % 7) + 1) || 7;
    };

    const calculateStreakMultiplier = (streakDays: number) => {
      if (streakDays >= 90) return STREAK_MULTIPLIERS.QUARTER;
      if (streakDays >= 30) return STREAK_MULTIPLIERS.MONTH;
      if (streakDays >= 7) return STREAK_MULTIPLIERS.WEEK;
      return 1;
    };

    const getNextMilestone = (streakDays: number) => {
      if (streakDays < 7) return 7;
      if (streakDays < 30) return 30;
      if (streakDays < 90) return 90;
      return Math.ceil(streakDays / 90) * 90;
    };

    const days = calculateDaysSinceRegistration();
    setCurrentDay(days);

    const lastReward = user.lastDailyReward ? new Date(user.lastDailyReward) : null;
    const today = new Date();
    
    if (lastReward) lastReward.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    
    const isNewDay = !lastReward || lastReward.getTime() < today.getTime();
    setCanClaim(isNewDay);

    // Calculate streak info
    const currentStreak = user.streakDays || 0;
    const multiplier = calculateStreakMultiplier(currentStreak);
    const nextMilestone = getNextMilestone(currentStreak);

    setStreakInfo({
      current: currentStreak,
      multiplier,
      nextMilestone
    });

    const mappedRewards = DAILY_REWARDS.map((reward, index) => {
      const dayNumber = index + 1;
      const isAvailable = days >= dayNumber;
      const isClaimed = !isNewDay && isAvailable && days === dayNumber;
      
      return {
        ...reward,
        claimed: isClaimed,
        available: isAvailable,
        progress: Math.min(100, ((days - 1) / 7) * 100)
      };
    });

    setRewards(mappedRewards);
    setLoading(false);
  }, [user]);

  const claimReward = async (day: number) => {
    if (!user || loading || !canClaim) return null;

    try {
      const reward = DAILY_REWARDS.find(r => r.day === day);
      if (!reward) return null;

      if (currentDay !== day) {
        toast.error(`You can only claim Day ${currentDay}'s reward today!`);
        return null;
      }

      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }

      const lastReward = new Date(userDoc.data().lastDailyReward || 0);
      const today = new Date();
      
      lastReward.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      
      if (lastReward.getTime() === today.getTime()) {
        toast.error('You have already claimed your daily reward!');
        return null;
      }

      const lastRewardTime = lastReward.getTime();
      const todayTime = today.getTime();
      const isConsecutiveDay = (todayTime - lastRewardTime) <= (24 * 60 * 60 * 1000);
      
      // Calculate bonuses
      const streakBonus = isConsecutiveDay ? Math.floor(reward.points * (streakInfo.multiplier - 1)) : 0;
      const totalPoints = reward.points + streakBonus;

      // Special milestone rewards
      let milestoneBonus = 0;
      let milestoneMessage = '';
      
      if (isConsecutiveDay) {
        const newStreak = user.streakDays + 1;
        if (newStreak === 7) {
          milestoneBonus = 1000;
          milestoneMessage = '🎉 Week Streak Achievement!';
        } else if (newStreak === 30) {
          milestoneBonus = 5000;
          milestoneMessage = '🌟 Month Streak Achievement!';
        } else if (newStreak === 90) {
          milestoneBonus = 15000;
          milestoneMessage = '👑 Quarter Streak Achievement!';
        }
      }

      const finalPoints = totalPoints + milestoneBonus;

      await updateDoc(userRef, {
        points: user.points + finalPoints,
        lastDailyReward: today.toISOString(),
        streakDays: isConsecutiveDay ? (user.streakDays + 1) : 1,
        totalEarned: user.totalEarned + finalPoints,
        'bonusPoints.daily': (user.bonusPoints?.daily || 0) + finalPoints,
        transactions: [
          ...user.transactions,
          {
            id: `daily-${Date.now()}`,
            amount: finalPoints,
            type: 'daily',
            timestamp: today.toISOString(),
            description: `Daily reward: ${reward.bonus}${streakBonus ? ` (+${streakBonus} streak bonus)` : ''}${milestoneBonus ? ` (+${milestoneBonus} milestone bonus)` : ''}`,
            status: 'completed'
          }
        ]
      });

      // Play reward sound
      const audio = new Audio('/sounds/coin.mp3');
      audio.play().catch(() => {});

      if (milestoneMessage) {
        toast.success(milestoneMessage, {
          duration: 5000,
          icon: reward.icon
        });
      }

      setCanClaim(false);
      setRewards(rewards.map(r => 
        r.day === day ? { ...r, claimed: true } : r
      ));

      return { 
        totalPoints: finalPoints,
        streakBonus,
        milestoneBonus,
        streakDays: isConsecutiveDay ? user.streakDays + 1 : 1,
        icon: reward.icon
      };
    } catch (error) {
      console.error('Error claiming reward:', error);
      toast.error('Failed to claim reward');
      return null;
    }
  };

  return { 
    rewards, 
    claimReward, 
    loading, 
    currentDay, 
    canClaim,
    streakInfo
  };
}