import { motion } from 'framer-motion';
import { DollarSign, Users } from 'lucide-react';
import GamingCard from './GamingCard';

const PROOFS = [
  { amount: '$40.00', method: 'PayPal', date: '2 hours ago', user: 'Sarah J.', country: '🇺🇸' },
  { amount: '$25.00', method: 'Bitcoin', date: '5 hours ago', user: 'Mike R.', country: '🇬🇧' },
  { amount: '$35.00', method: 'PayPal', date: '8 hours ago', user: 'David W.', country: '🇨🇦' },
  { amount: '$15.00', method: 'Amazon', date: '12 hours ago', user: 'Emma S.', country: '🇦🇺' },
  { amount: '$50.00', method: 'Bitcoin', date: '1 day ago', user: 'Alex M.', country: '🇩🇪' },
  { amount: '$30.00', method: 'PayPal', date: '1 day ago', user: 'Lisa K.', country: '🇫🇷' }
];

export default function PayoutProofs() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {PROOFS.map((proof, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          whileHover={{ scale: 1.02 }}
        >
          <GamingCard className="p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center space-x-2">
                <div className="w-8 h-8 rounded-full bg-cyan-500/20 flex items-center justify-center">
                  <Users className="h-4 w-4 text-cyan-400" />
                </div>
                <div>
                  <span className="text-sm text-gray-400">{proof.user}</span>
                  <span className="ml-2 text-lg">{proof.country}</span>
                </div>
              </div>
              <span className="text-xs text-gray-500">{proof.date}</span>
            </div>
            <div className="flex items-center justify-between mt-4">
              <span className="text-xl font-bold text-green-400">{proof.amount}</span>
              <div className="flex items-center space-x-1 bg-cyan-500/20 px-3 py-1.5 rounded-full">
                <DollarSign className="h-4 w-4 text-cyan-400" />
                <span className="text-sm text-cyan-400">{proof.method}</span>
              </div>
            </div>

            {/* Animated Success Indicator */}
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: '100%' }}
              transition={{ duration: 1, delay: index * 0.2 }}
              className="mt-4 h-1 bg-gradient-to-r from-green-500 to-cyan-500 rounded-full"
            />
          </GamingCard>
        </motion.div>
      ))}
    </div>
  );
}