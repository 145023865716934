import { motion } from 'framer-motion';
import { Trophy, Star, Crown, Sparkles } from 'lucide-react';

interface UserLevelProps {
  points: number;
}

export default function UserLevel({ points }: UserLevelProps) {
  const calculateLevel = (points: number) => {
    return Math.floor(Math.sqrt(points / 100)) + 1;
  };

  const calculateProgress = (points: number) => {
    const currentLevel = calculateLevel(points);
    const pointsForCurrentLevel = (currentLevel - 1) * (currentLevel - 1) * 100;
    const pointsForNextLevel = currentLevel * currentLevel * 100;
    const progress = ((points - pointsForCurrentLevel) / (pointsForNextLevel - pointsForCurrentLevel)) * 100;
    return Math.min(Math.max(progress, 0), 100);
  };

  const level = calculateLevel(points);
  const progress = calculateProgress(points);
  const pointsToNextLevel = (level * level * 100) - points;

  const getLevelIcon = (level: number) => {
    if (level >= 50) return Crown;
    if (level >= 25) return Trophy;
    return Star;
  };

  const LevelIcon = getLevelIcon(level);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-white to-indigo-50 dark:from-gray-800 dark:to-gray-900 rounded-2xl shadow-xl p-8 border border-indigo-100 dark:border-indigo-900 relative overflow-hidden"
    >
      <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-radial from-indigo-500/10 to-transparent opacity-50" />
      
      <div className="flex items-center justify-between mb-6 relative">
        <h3 className="text-xl font-bold text-gray-900 dark:text-white flex items-center">
          <Sparkles className="h-6 w-6 text-yellow-500 mr-2 animate-pulse-slow" />
          Level Progress
        </h3>
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="flex items-center space-x-2 bg-gradient-to-r from-yellow-400 to-amber-500 p-3 rounded-full shadow-glow"
        >
          <LevelIcon className="h-6 w-6 text-white" />
          <span className="text-2xl font-bold text-white">
            {level}
          </span>
        </motion.div>
      </div>

      <div className="relative pt-1">
        <div className="flex mb-3 items-center justify-between">
          <motion.div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <span className="text-sm font-semibold inline-block py-1 px-3 rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
              Level {level}
            </span>
          </motion.div>
          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="text-right"
          >
            <span className="text-sm font-semibold inline-block py-1 px-3 rounded-full bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-300">
              {Math.round(progress)}%
            </span>
          </motion.div>
        </div>
        <div className="h-3 mb-4 relative rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 1, ease: "easeOut" }}
            className="h-full rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 relative"
          >
            <div className="absolute inset-0 bg-white/20 animate-pulse-slow" />
          </motion.div>
        </div>
        <motion.p
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="text-sm text-center bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent font-medium"
        >
          {pointsToNextLevel.toLocaleString()} points to Level {level + 1}
        </motion.p>
      </div>

      <div className="mt-6 grid grid-cols-3 gap-4">
        {[
          { label: 'Current Level', value: level, color: 'from-blue-500 to-indigo-500' },
          { label: 'Total Points', value: points.toLocaleString(), color: 'from-purple-500 to-pink-500' },
          { label: 'Next Level', value: level + 1, color: 'from-green-500 to-emerald-500' }
        ].map((stat, index) => (
          <motion.div
            key={index}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 + index * 0.1 }}
            className="text-center p-3 rounded-xl bg-white/5 backdrop-blur-sm border border-white/10 hover:border-white/20 transition-colors"
          >
            <p className="text-sm text-gray-500 dark:text-gray-400">{stat.label}</p>
            <p className={`text-lg font-bold bg-gradient-to-r ${stat.color} bg-clip-text text-transparent`}>
              {stat.value}
            </p>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}