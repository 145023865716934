import { motion } from 'framer-motion';
import { Trophy, Award, Medal, Star, Users, Target, Crown, Sparkles, TrendingUp } from 'lucide-react';
import { useLeaderboard } from '../hooks/useLeaderboard';
import LoadingSpinner from '../components/LoadingSpinner';
import PageTransition from '../components/PageTransition';
import GamingCard from '../components/GamingCard';
import GamingHeading from '../components/GamingHeading';

const STATS = [
  {
    id: 'total-points',
    title: 'Total Points',
    icon: Star,
    gradient: 'from-yellow-400 to-yellow-600',
    textColor: 'yellow',
    getValue: (leaders) => leaders.reduce((sum, user) => sum + user.points, 0)
  },
  {
    id: 'total-offers',
    title: 'Total Offers',
    icon: Target,
    gradient: 'from-purple-400 to-purple-600',
    textColor: 'purple',
    getValue: (leaders) => leaders.reduce((sum, user) => sum + user.completedOffers, 0)
  },
  {
    id: 'average-points',
    title: 'Average Points',
    icon: Award,
    gradient: 'from-cyan-400 to-blue-400',
    textColor: 'cyan',
    getValue: (leaders) => Math.floor(
      leaders.reduce((sum, user) => sum + user.points, 0) / (leaders.length || 1)
    )
  }
];

const PODIUM_CONFIG = [
  {
    id: 'first',
    gradient: 'from-yellow-400 to-yellow-600',
    icon: Crown,
    iconClass: 'text-yellow-200 animate-bounce-slow',
    position: 'order-2',
    scale: 1.1,
    height: 'h-80'
  },
  {
    id: 'second',
    gradient: 'from-gray-400 to-gray-600',
    icon: Trophy,
    iconClass: 'text-gray-200',
    position: 'order-1',
    scale: 1,
    height: 'h-72'
  },
  {
    id: 'third',
    gradient: 'from-orange-400 to-orange-600',
    icon: Medal,
    iconClass: 'text-orange-200',
    position: 'order-3',
    scale: 1,
    height: 'h-64'
  }
];

export default function Leaderboard() {
  const { leaders, loading } = useLeaderboard();

  if (loading) {
    return <LoadingSpinner />;
  }

  const topThree = leaders.slice(0, 3);
  const restOfLeaders = leaders.slice(3);

  return (
    <PageTransition>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-8">
          <div>
            <GamingHeading level={1} variant="gradient" className="text-4xl mb-2">
              Top Earners
            </GamingHeading>
            <p className="text-gray-400">
              Compete with other users and earn rewards!
            </p>
          </div>
          <div className="flex items-center space-x-2 mt-4 sm:mt-0">
            <Users className="h-5 w-5 text-cyan-400" />
            <span className="text-gray-400">
              {leaders.length} Players
            </span>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6 mb-8">
          {STATS.map((stat) => (
            <motion.div
              key={stat.id}
              whileHover={{ scale: 1.02 }}
              className={`bg-gradient-to-br ${stat.gradient} rounded-lg p-6 text-white relative overflow-hidden`}
            >
              <div className="absolute inset-0 bg-black/10" />
              <div className="relative">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="text-white/80">{stat.title}</p>
                    <p className="mt-2 text-3xl font-bold">
                      {stat.getValue(leaders).toLocaleString()}
                    </p>
                  </div>
                  <stat.icon className="h-8 w-8 text-white/80" />
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Podium */}
        <div className="relative mb-12">
          <div className="absolute inset-0 bg-gradient-to-b from-cyan-500/10 to-transparent" />
          <div className="relative">
            {/* Crown Animation */}
            <motion.div
              animate={{ 
                y: [0, -10, 0],
                rotate: [-5, 5, -5]
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-12"
            >
              <Crown className="h-16 w-16 text-yellow-400" />
            </motion.div>

            <div className="flex items-end justify-center space-x-4 pt-16">
              {topThree.map((user, index) => {
                const config = PODIUM_CONFIG[index];
                const Icon = config.icon;

                return (
                  <motion.div
                    key={user.uid}
                    className={`${config.position} ${config.height} w-full max-w-xs`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                    whileHover={{ scale: config.scale }}
                  >
                    <GamingCard className="h-full relative overflow-hidden">
                      {/* Background Effects */}
                      <div className={`absolute inset-0 bg-gradient-to-br ${config.gradient} opacity-10`} />
                      <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(255,255,255,0.1),transparent)]" />

                      <div className="relative p-6 flex flex-col h-full">
                        {/* Position Badge */}
                        <div className="absolute top-4 right-4">
                          <div className={`w-10 h-10 rounded-full bg-gradient-to-br ${config.gradient} flex items-center justify-center`}>
                            <span className="text-white font-bold">#{index + 1}</span>
                          </div>
                        </div>

                        {/* User Info */}
                        <div className="flex-grow flex flex-col items-center justify-center text-center">
                          <div className="relative mb-4">
                            <div className="w-24 h-24 rounded-full overflow-hidden border-4 border-white/10">
                              <img
                                src={user.avatar}
                                alt={user.displayName}
                                className="w-full h-full object-cover"
                              />
                            </div>
                            <span className="absolute bottom-0 right-0 text-2xl">
                              {user.country}
                            </span>
                          </div>

                          <h3 className="text-xl font-bold text-white mb-2">
                            {user.displayName}
                          </h3>

                          <div className="space-y-2">
                            <div className="flex items-center justify-center space-x-2">
                              <Star className="h-5 w-5 text-yellow-400" />
                              <span className="text-lg font-bold text-yellow-400">
                                {user.points.toLocaleString()} pts
                              </span>
                            </div>

                            <div className="flex items-center justify-center space-x-2">
                              <Target className="h-4 w-4 text-cyan-400" />
                              <span className="text-sm text-cyan-400">
                                {user.completedOffers} offers
                              </span>
                            </div>
                          </div>
                        </div>

                        {/* Trophy Icon */}
                        <Icon className={`absolute top-4 left-4 h-6 w-6 ${config.iconClass}`} />
                      </div>
                    </GamingCard>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Leaderboard Table */}
        <GamingCard>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-800">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Rank
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    User
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Points
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Completed Offers
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Country
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {restOfLeaders.map((user, index) => (
                  <motion.tr
                    key={user.uid}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="hover:bg-gray-700/50 transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-gray-300 font-medium">
                        #{index + 4}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <img
                          className="h-10 w-10 rounded-full border-2 border-gray-700"
                          src={user.avatar}
                          alt={user.displayName}
                        />
                        <div className="ml-4">
                          <div className="text-sm font-medium text-white">
                            {user.displayName}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <Star className="h-4 w-4 text-yellow-400" />
                        <span className="text-white">
                          {user.points.toLocaleString()}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <Target className="h-4 w-4 text-cyan-400" />
                        <span className="text-white">
                          {user.completedOffers}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-2xl">{user.country}</span>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </GamingCard>
      </div>
    </PageTransition>
  );
}